import React from 'react';
import PropTypes from 'prop-types';
import history from '@dtsl/common-billing-components/src/Utils/history';
import Fallback from './ErrorBoundaryFallback';

class Setup extends React.Component {
	constructor(props) {
		super();
		this.state = { hasError: false };
	}

	componentDidMount() {
		this.unlisten = history.listen(() => {
			if (this.state.hasError) {
				this.setState({
					hasError: false,
				});
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return <Fallback />;
		}

		return this.props.children;
	}
}

Setup.propTypes = {
	children: PropTypes.element.isRequired
};

export default Setup;
