import { FETCH_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/actionTypes';

const initialState = {
	bannerInfo: {}
};

const bannerNotification = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_NOTIFICATION:
			return { bannerInfo: action.payload };

		case REMOVE_NOTIFICATION:
			return { bannerInfo: {} };

		default:
			return state;
	}
};

export default bannerNotification;
