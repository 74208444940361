export const IP_ASSIGNMENT_TAB = 'assignment';
export const IP_CONFIGURATION_TAB = 'configuration';
export const DOMAINS_TAB = 'domains';

export const IP_ASSIGNMENT_PATH = '/ip/assignment';
export const IP_CONFIGURATION_PATH = '/ip/configuration';
export const DOMAINS_PATH = '/ip/domains';

export const ASSOCIATE_DOMAIN_PATH = '/ip/configuration/associate';
export const CONFIGURE_DOMAIN_PATH = '/ip/configuration/configure';

export const DOMAIN_REG_EX = /^(?!www\.)([a-z0-9\-]+\.)*[a-z0-9\-]+\.[a-z0-9]{2,15}(\.[a-z0-9]{2,15})?(\/.*)?$/i;

export const SIMPLE = 'simple';
export const ADVANCED = 'advanced';

export const recordTypes = {
	A: 'a',
	MX: 'mx',
	R: 'r',
	IMG: 'img',
	DKIM: 'dkim',
	DKIM_CNAME1: 'dkim_cname1',
	DKIM_CNAME2: 'dkim_cname2',
	SPF: 'spf',
	DNS: 'brevo_code',
	DMARC: 'dmarc'
};

export const NEW_TAG_DAYS_COUNT = 30;

export const DMARC_POLICY_MISSING = 'no_dmarc_policy';
export const DMARC_POLICY_RUA_TAG_MISSING = 'no_dmarc_rua_tag';
export const DMARC_POLICY_NO_ERROR = 'no_error';
