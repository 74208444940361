import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from './utils/helper';
import Loader from './components/common/Loader';
import ErrorBoundary from './components/errorBoundary/SetupErrorBoundary';
import messages_en from './translations/translation-en.json';
import Root from './Root';
import { initElasticRUM } from './utils/elasticRum';
import RootEntPricing from './RootEntPricing';

const languageWiseLocale = {
	en: 'en-US',
	de: 'de-DE',
	es: 'es-ES',
	fr: 'fr-FR',
	it: 'it-IT',
	pt: 'pt-BR'
};

class ConnectedIntlProvider extends React.Component {
	constructor() {
		super();
		this.state = {
			messages: {},
			commonBillingMessages: {}
		};
	}

	componentDidMount() {
		const { language } = this.props;
		this.fetchTranslations(language);
		this.fetchCommanTranslations(language);
		APP_ENV === 'production' && initElasticRUM();
	}

	componentDidUpdate(prevProps) {
		const { language: prevLanguage } = prevProps;
		const { language } = this.props;

		if (prevLanguage !== language) {
			if (language !== 'en') {
				this.fetchTranslations(language);
			}
			this.fetchCommanTranslations(language);
		}
	}

	fetchCommanTranslations = async (language) => {
		const CommonMessages = await import(
			`@dtsl/common-billing-components/src/translations/translation-${language || 'en'}.json`
		);
		this.setState({ commonBillingMessages: CommonMessages.default });
	}

	fetchTranslations=async (language) => {
		if (language !== 'en') {
			const messages = await import(
				`./translations/translation-${language || 'en'}.json`
			);
			this.setState({ messages: messages.default });
		}
	}

	render() {
		const { messages, commonBillingMessages } = this.state;
		const { language, isEnterpriseV2 } = this.props;

		const flattenedMessages = Object.keys(messages).length || language === 'en'
			? (
				language === 'en'
					? {
						...flattenMessages(messages_en),
						...flattenMessages(commonBillingMessages)
					}
					: {
						...flattenMessages(messages_en),
						...flattenMessages(messages),
						...flattenMessages(commonBillingMessages)
					}
			) : {};

		return Object.keys(messages).length || language === 'en' ? (
			<IntlProvider
				locale={language}
				defaultLocale="en"
				messages={flattenedMessages}
			>
				<ErrorBoundary>
					{isEnterpriseV2 ? <RootEntPricing /> : <Root />}
				</ErrorBoundary>
			</IntlProvider>
		) : <Loader style={{ height: '100vh' }} />;
	}
}

function mapStateToProps({ corpAccountInfo: { language, isEnterpriseV2 } }) {
	return { language, isEnterpriseV2 };
}

ConnectedIntlProvider.propTypes = {
	language: PropTypes.string.isRequired,
	isEnterpriseV2: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ConnectedIntlProvider);
