import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { InfoCard, Link } from '@dtsl/react';
import { closeBanner } from '../../actions/bannerNotification';
import { checkShowNotificationBanner, getBannerInfo } from './helper';
import { BannerSourcePricing } from './constants';

function BannerNotification() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const [showBanner, setshowBanner] = useState(checkShowNotificationBanner(location.pathname));

	useEffect(() => {
		setshowBanner(checkShowNotificationBanner(location.pathname));
	}, [location.pathname]);

	const {
		source,
		banner_type,
		variant,
		data,
		meta_data = {}
	} = useSelector((state) => state?.bannerNotification?.bannerInfo) || {};

	if (source !== BannerSourcePricing) return null;

	const translationKey = getBannerInfo(banner_type, data?.plan_expired_date, meta_data?.pending_invoices);

	const handleClose = () => {
		dispatch(closeBanner());
	};

	const args = {
		variant,
		closable: true,
		onClose: handleClose,
		content: (
			<div>
				<FormattedMessage
					id={translationKey}
					values={{
						a: (chunk) => (
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<Link
								href="#"
								onClick={() => history.push('/billing/invoices')}
								labelText={chunk}
							/>
						)
					}}
				/>
			</div>
		)
	};

	return (
		(showBanner && translationKey)
			? (
				<div className="banner-notification">
					<InfoCard {...args} />
				</div>
			) : <></>
	);
}

export default BannerNotification;
