import {
	UPDATING_PREFERENCES,
	UPDATE_PASSWORD,
	FETCH_PROFILE_INFO,
	UPDATE_PROFILE_INFO,
	IS_SMS_VALIDATION_SENT,
	IS_EMAIL_VALIDATION_SENT,
	SEND_OTP,
	FETCH_PHONE_CHANGE_INFO,
	SUBMIT_OTP
} from '../actions/actionTypes';

const initialState = {
	preferences: {
		updatingPreferences: false
	},
	password: {
		passwordUpdated: false,
		updatingPassword: false,
		validationError: {}
	},
	profileInfo: {
		personalInfo: {},
		companyInfo: {},
		fetchingInfo: false,
		isFetched: false
	},
	updateProfileInfo: {
		updatingInfo: false,
		validationError: {},
		isValidationSmsSent: true,
		isValidationEmailSent: false
	},
	phoneValidationInfo: {
		sendingOtp: false,
		submittingOtp: false,
		fetchingInfo: false,
		otpValidationError: false,
		phoneNumber: ''
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATING_PREFERENCES:
			return { ...state, preferences: { updatingPreferences: action.payload } };
		case FETCH_PROFILE_INFO:
			return { ...state, profileInfo: action.payload };
		case UPDATE_PASSWORD:
			return { ...state, password: action.payload };
		case UPDATE_PROFILE_INFO: {
			const updateProfileInfo = { ...state.updateProfileInfo, ...action.payload };
			return { ...state, updateProfileInfo };
		}
		case IS_EMAIL_VALIDATION_SENT: {
			const newUpdateProfileInfo = { ...state.updateProfileInfo, isValidationEmailSent: action.payload };
			return { ...state, updateProfileInfo: newUpdateProfileInfo };
		}
		case IS_SMS_VALIDATION_SENT: {
			const newUpdateProfileInfo = { ...state.updateProfileInfo, isValidationSmsSent: action.payload };
			return { ...state, updateProfileInfo: newUpdateProfileInfo };
		}
		case SEND_OTP: {
			const phoneValidationInfo = { ...state.phoneValidationInfo, sendingOtp: action.payload };
			return { ...state, phoneValidationInfo };
		}
		case FETCH_PHONE_CHANGE_INFO: {
			const phoneValidationInfo = { ...state.phoneValidationInfo, ...action.payload };
			return { ...state, phoneValidationInfo };
		}
		case SUBMIT_OTP: {
			const phoneValidationInfo = { ...state.phoneValidationInfo, ...action.payload };
			return { ...state, phoneValidationInfo };
		}
		default:
			return state;
	}
};
