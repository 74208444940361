/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Activity, BookOpen, Company, HelpCircle, LifeBuoy, Lock, LogOut, Repeat, Server, Shield, User, Users } from '@dtsl/icons';
import { Logo } from '@dtsl/react';
import icons from '../../constants/headerIcons';

const {
	ORGANIZATION_LOGO,
	HELP_ICON,
	RESOURCES_ICON,
	DOCUMENTATION_ICON,
	PROFILE_ICON,
	PLAN_ICON,
	SWITCH_ACCOUNT_ICON,
	LOGOUT_ICON,
	SUPPORT,
	USERS_ICON,
	SHIELD,
	LOCK_ICON,
	API_KEYS,
	GROUPS
} = icons;

function HeaderIcons({ iconType }) {
	switch (iconType) {
		case ORGANIZATION_LOGO:
			return (
				<Logo
					to="/"
					onClick={() => { }}
					style={{
						height: '29px',
						width: '95px',
					}}
				/>
			);
		case HELP_ICON:
			return (
				<HelpCircle />
			);
		case RESOURCES_ICON:
			return (
				<HelpCircle />
			);
		case DOCUMENTATION_ICON:
			return (
				<BookOpen />
			);
		case PROFILE_ICON:
			return (
				<User />
			);
		case PLAN_ICON:
			return (
				<Activity />
			);
		case SWITCH_ACCOUNT_ICON:
			return (
				<Repeat />
			);
		case LOGOUT_ICON:
			return (
				<LogOut />
			);
		case SUPPORT:
			return (
				<LifeBuoy />
			);
		case USERS_ICON:
			return (
				<Users />
			);
		case SHIELD:
			return (
				<Shield />
			);
		case LOCK_ICON:
			return (
				<span data-testid="lockIcon">
					<Lock />
				</span>
			);
		case API_KEYS:
			return (
				<Server />
			);
		case GROUPS:
			return (
				<Company />
			);
		default: return null;
	}
}

HeaderIcons.propTypes = {
	iconType: PropTypes.string.isRequired
};

export default HeaderIcons;
