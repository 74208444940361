import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import history from '@dtsl/common-billing-components/src/Utils/history';
import { SnackbarContainer } from '@dtsl/react';
import authWrapper from './authWrapper';
import SuspenseWrapper from './components/SuspenseWrapper';
import Layout from './components/Layout';

const HomePage = lazy(() =>
	import(/* webpackChunkName: "rootComp" */ './components/HomePage'));
const AddAccount = lazy(() =>
	import(/* webpackChunkName: "AddAccount" */ './components/accounts/AddAccount'));
const PageNotFound = lazy(() =>
	import(/* webpackChunkName: "PageNotFound" */ './components/PageNotFound'));
const ProfileContainer = lazy(() =>
	import(/* webpackChunkName: "ProfileContainer" */ './components/profile'));
const BillingContainer = lazy(() =>
	import(/* webpackChunkName: "BillingContainer" */'./components/billing/BillingContainer'));
const VerifyPhone = lazy(() =>
	import(/* webpackChunkName: "VerifyPhone" */ './components/profile/verifyPhone'));
const costomizeSubaccountPlan = lazy(() =>
	import(/* webpackChunkName: 'CustomizePlan' */ './components/costomizeSubaccountPlan'));
const UserManagementContainer = lazy(() =>
	import(/* webpackChunkName: 'userManagement' */'./components/accounts/UserManagement'));
const inviteNewUser = lazy(() =>
	import(/* webpackChunkName: 'userManagement' */'./components/accounts/InviteNewUser'));
const editInvitedUser = lazy(() =>
	import(/* webpackChunkName: 'userManagement' */'./components/accounts/EditInvitedUser'));
const pageAccessDenied = lazy(() =>
	import(/* webpackChunkName: 'pageAccessDenied' */'./components/PageAccessDenied'));
const inviteNewSubaccountUser = lazy(() =>
	import(/* webpackChunkName: 'AddNewUser' */
		'./components/accounts/UserManagement/SubaccountUserManagement/AddNewUser/InviteNewUser'
	));
const associateSubaccounts = lazy(() =>
	import(/* webpackChunkName: 'AddNewUser' */
		'./components/accounts/UserManagement/SubaccountUserManagement/AssociateSubacc'
	));
const Saml = lazy(() =>
	import(/* webpackChunkName: "rootComp" */ './components/security/Saml'));
const analyticsContainer = lazy(() =>
	import(/* webpackChunkName: 'analytics' */'./components/accounts/Analytics'));
const looksWrapper = lazy(() =>
	import(/* webpackChunkName: 'looks' */'./components/accounts/Analytics/AnalyticsWrapper'));
const apiKeys = lazy(() =>
	import(/* webpackChunkName: 'apiKeys' */'./components/api-keys/ApiKeysWrapper'));
const IntermediatePaymentPage = lazy(() =>
	import(
		/* webpackChunkName: 'IntermediatePaymentPage' */
		'@dtsl/common-billing-components/src/PaymentMethods/IntermediatePaymentPage'
	));

const groupManagementPage = lazy(() =>
	import(
		/* webpackChunkName: 'groupManagementPage' */
		'./components/GroupManagement/GroupsListing'
	));

const createGroup = lazy(() =>
	import(
		/* webpackChunkName: 'createGroup' */
		'./components/GroupManagement/AddGroup/CreateGroup'
	));

const editGroup = lazy(() =>
	import(
		/* webpackChunkName: 'editGroup' */
		'./components/GroupManagement/EditGroup/EditGroupForm'
	));

const importStyleOverride = () => {
	import('./assets/less/stylesOverride.less');
	import('./assets/less/themesOverride.less');
};

function Root() {
	const { isEnterpriseV2 } = useSelector((state) => state?.corpAccountInfo);

	!isEnterpriseV2 && importStyleOverride();
	const routePaths = [
		{ url: '/', component: HomePage },
		{ url: '/ip-management', component: HomePage },
		{ url: '/accounts/add', component: AddAccount },
		{ url: '/profile/phone-number/verification', component: VerifyPhone },
		{ url: '/plan/sub-account/customize/:subaccountId', component: costomizeSubaccountPlan },
		{ url: '/organization/invitation/send', component: inviteNewUser },
		{ url: '/organization/member/role/:id', component: editInvitedUser },
		{ url: '/pageAccessDenied', component: pageAccessDenied },
		{ url: '/add-user', component: inviteNewSubaccountUser },
		{ url: '/user/connect-sub-account', component: associateSubaccounts },
		{ url: '/user/connect-sub-account/:subaccountId', component: associateSubaccounts },
		{ url: '/saml/configure', component: Saml },
		{ url: '/manage/api', component: apiKeys },
		// Remove when @addSender feature goes live
		{ url: '/ip-management/test-add-sender', component: HomePage },
	];

	// Only owner admin user has permissions to create & modify groups
	const groupManagementPaths = [
		{ url: '/groups', component: groupManagementPage },
		{ url: '/create-group', component: createGroup },
		{ url: '/edit-group', component: editGroup },
	];
	routePaths.push(...groupManagementPaths);

	const profilePages = [
		'/profile/preferences',
		'/profile/password',
		'/profile/information'
	];

	const billingPages = [
		'/billing/my-plan',
		'/billing/payment-methods',
		'/billing/billing-information',
		'/billing/invoices',
		'/billing/credit-notes',
		'/billing/stripe/processing'
	];

	const userManagementPages = [
		'/organization/invited/users',
		'/sub-accounts/invited/users'
	];

	const analyticsPages = [
		'/analytics'
	];

	const looksPages = [
		'/analytics/looks'
	];

	const intermediateBillingPage = [
		'/billing/payment-methods/add'
	];

	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={[...routePaths.map(({ url }) => url),
						...profilePages, ...billingPages, ...userManagementPages,
						 ...analyticsPages, ...looksPages, ...intermediateBillingPage]}
				>
					<SnackbarContainer>
						<Layout>

							<SuspenseWrapper>
								<>
									{
										routePaths.map(({ url, component }) => {
											return <Route key={url} path={url} exact component={component} />;
										})
									}
									<Route path={profilePages} exact component={ProfileContainer} />
									<Route path={billingPages} exact component={BillingContainer} />
									<Route path={userManagementPages} exact component={UserManagementContainer} />
									<Route path={analyticsPages} exact component={looksWrapper} />
									<Route path={looksPages} exact component={looksWrapper} />
									<Route path={intermediateBillingPage} exact component={IntermediatePaymentPage} />

								</>
							</SuspenseWrapper>
						</Layout>
					</SnackbarContainer>
				</Route>

				<SuspenseWrapper>
					<PageNotFound />
				</SuspenseWrapper>
			</Switch>
		</Router>
	);
}

export default authWrapper(Root);
