import {
	API_KEYS_RECEIVED,
	API_KEY_SELECTED,
	API_KEY_SELECTION_CLEARED,
	API_KEY_ADDED,
	API_KEY_ACTION_LOADING,
	API_KEY_DELETED,
	API_KEY_ACTION_ERROR,
	API_KEYS_FETCH_ERROR,
	API_KEY_ACTION_ERROR_RESET,
	API_KEYS_FETCHING,
	API_KEYS_IS_BACK_OFFICE
} from './actionTypes';

const initialState = {
	keys: [],
	isBackOffice: false,
	selectedKey: null,
	isFetching: false,
	isFetchError: false,
	isActionLoading: false,
	isActionError: false,
	error: {}
};

function apiKeysReducer(state = initialState, action) {
	switch (action.type) {
		case API_KEYS_FETCHING:
			return {
				...state,
				isFetching: true
			};
		case API_KEYS_RECEIVED:
			return {
				...state,
				keys: action.payload,
				isFetching: false,
				isFetchError: false
			};
		case API_KEYS_FETCH_ERROR:
			return {
				...state,
				isFetching: false,
				isFetchError: true,
				error: action.payload
			};
		case API_KEY_ADDED:
			return {
				...state,
				keys: [...state.keys, action.payload]
			};
		case API_KEY_DELETED:
			return {
				...state,
				keys: state.keys.filter(
					(keyObj) => keyObj.key !== action.payload
				)
			};
		case API_KEY_SELECTED: {
			return {
				...state,
				selectedKey: action.payload
			};
		}
		case API_KEY_SELECTION_CLEARED: {
			return {
				...state,
				selectedKey: null
			};
		}
		case API_KEY_ACTION_LOADING: {
			return {
				...state,
				isActionLoading: action.payload
			};
		}
		case API_KEY_ACTION_ERROR: {
			return {
				...state,
				isActionError: true,
				error: action.payload
			};
		}
		case API_KEY_ACTION_ERROR_RESET: {
			return {
				...state,
				isActionError: false,
				error: {}
			};
		}
		case API_KEYS_IS_BACK_OFFICE: {
			return {
				...state,
				isBackOffice: true
			};
		}
		default:
			return state;
	}
}

export default apiKeysReducer;
