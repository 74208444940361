import {
	FETCH_NEW_ACCOUNT_PLAN,
	FETCHING_NEW_ACCOUNT_PLAN,
	PLAN_LOAD_ERROR,
	FETCH_FEATURE_USES,
	FETCHING_FEATURE_USES,
	FETCH_CORP_PLAN,
	FETCHING_PLAN,
	MODIFY_ACTIVATED_APPSLIST,
	FETCH_CAN_RETRY,
	FETCHING_FEATURE_USES_ENTV2,
	FETCH_FEATURE_USES_ENTV2,
	RETRY_COUNT_ENTV2,
	FETCH_MASTER_PLAN_ENTV2,
	FETCHING_MASTER_PLAN_ENTV2,
	FETCH_MISSING_IDS_PLAN_DATA,
	FETCHING_MISSING_IDS_PLAN_DATA,
	FETCHING_GROUPS_BADGES,
	FETCH_GROUPS_BADGES,
	FETCHING_ADDONS_DETAILS,
	FETCH_ADDONS_DETAILS,
} from '../actions/actionTypes';
import { getModifiedActivatedAppsList } from '../components/appManagement/helper';

const initialState = {
	newAccountPlan: {},
	corpPlan: {},
	featureUses: {},
	planLoadError: false,
	fetchingPlan: false,
	isPlanFetched: false,
	fetchingEntV2PlanDetails: false,
	activatedApps: {},
	featureUsesEntV2: {},
	retryCountofEntV2PlanDetails: 0,
	masterPlanData: {},
	fetchingMasterPlanV2: false,
	isMasterPlanDataFetched: false,
	fetchingMissingIdsPlanData: false,
	isFetchMissingIdsPlanDone: false,
	fetchingGroupsBadges: false,
	groupsBadge: {},
	fetchingAddonsDetails: false,
	addonsDetails: {},
};

const plan = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_NEW_ACCOUNT_PLAN:
			return { ...state, ...action.payload };

		case FETCHING_NEW_ACCOUNT_PLAN:
			return { ...state, ...action.payload };

		case PLAN_LOAD_ERROR:
			return { ...state, planLoadError: action.payload };

		case FETCH_FEATURE_USES: {
			const { accountId, features, activatedAppsList } = action.payload;
			state.featureUses[accountId] = { ...state.featureUses[accountId], ...features };
			state.activatedApps[accountId] = activatedAppsList;
			return { ...state };
		}

		case FETCHING_FEATURE_USES: {
			const { accountId, featureData } = action.payload;
			state.featureUses[accountId] = featureData;
			return { ...state };
		}

		case FETCH_FEATURE_USES_ENTV2: {
			return { ...state, featureUsesEntV2: action.payload, isFetchMissingIdsPlanDone: false };
		}

		case FETCHING_FEATURE_USES_ENTV2: {
			return { ...state, fetchingEntV2PlanDetails: action.payload };
		}

		case FETCHING_MISSING_IDS_PLAN_DATA: {
			return { ...state, fetchingMissingIdsPlanData: action.payload };
		}

		case FETCH_MISSING_IDS_PLAN_DATA:
			return {
				...state,
				featureUsesEntV2: {
					data: {
						...state.featureUsesEntV2?.data,
						...action.payload.data
					},
					missing_ids: [...action.payload.missing_ids]
				},
				isFetchMissingIdsPlanDone: true
			};

		case RETRY_COUNT_ENTV2:
			return { ...state, retryCountofEntV2PlanDetails: action.payload };

		case FETCH_CORP_PLAN:
			return { ...state, corpPlan: action.payload };

		case FETCHING_PLAN:
			return { ...state, ...action.payload };

		case MODIFY_ACTIVATED_APPSLIST:
			return { ...state, activatedApps: getModifiedActivatedAppsList(state, action.payload) };

		case FETCHING_MASTER_PLAN_ENTV2:
			return { ...state, fetchingMasterPlanV2: action.payload };

		case FETCH_MASTER_PLAN_ENTV2:
			const { data: masterPlanData, isMasterPlanDataFetched } = action.payload;
			return { ...state, masterPlanData, isMasterPlanDataFetched };

		case FETCHING_GROUPS_BADGES:
			return { ...state, fetchingGroupsBadges: action.payload };

		case FETCH_GROUPS_BADGES:
			return { ...state, groupsBadge: action.payload };

		case FETCHING_ADDONS_DETAILS:
			return { ...state, fetchingAddonsDetails: action.payload };
		case FETCH_ADDONS_DETAILS:
			const { addonName, addonDetails } = action.payload;
			return {
				...state,
				addonsDetails: {
					...state.addonsDetails,
					[addonName]: addonDetails
				} };
		default:
			return state;
	}
};

export default plan;
