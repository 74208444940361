import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from './common/Loader';

const SuspenseWrapper = ({ children }) => {
	return (
		<Suspense
			fallback={(
				<div className="suspense__loader">
					<Loader />
				</div>
			)}
		>
			{children}
		</Suspense>
	);
};

SuspenseWrapper.propTypes = {
	children: PropTypes.element.isRequired
};

export default SuspenseWrapper;
