import { ElasticManager } from '@dtsl/jsutils';

export function initElasticRUM() {
	const elasticInitialiseData = {
		serviceName: 'subaccount-frontend',
		serverUrl: APM_URL,
		environment: APP_ENV,
		serviceVersion: '',
		breakdownMetrics: true
	};
	ElasticManager.methodInitiator('init', [elasticInitialiseData]);
}
