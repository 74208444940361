import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { setupInterceptors } from './actions/axios';
import store from './store';
import ConnectedIntlProvider from './ConnectedIntlProvider';

setupInterceptors();

const App = () => {
	return (
		<Provider store={store}>
			<ConnectedIntlProvider />
		</Provider>
	);
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);

if (module.hot) {
	module.hot.accept();
}
