import { FETCH_NOTIFICATION, REMOVE_NOTIFICATION } from './actionTypes';
import axios, { genericExceptionHandler } from './axios';
import URLS from '../utils/urlHelper';

export const removeNotication = () => {
	return {
		type: REMOVE_NOTIFICATION
	};
};

export const fetchNotification = () => {
	return async (dispatch) => {
		try {
			const { data } = await axios.get(`${URLS.NOTIFICATION_BANNER_API_URL}/banner-info`, { mode: 'cors' });
			dispatch({
				type: FETCH_NOTIFICATION,
				payload: data
			});
		} catch (error) {
			genericExceptionHandler(error, () => { });
		}
	};
};

export const closeBanner = (payload = {}) => {
	return async (dispatch) => {
		try {
			dispatch(removeNotication());
			await axios.post(`${URLS.NOTIFICATION_BANNER_API_URL}/banner-info/closed-date`, payload, { mode: 'cors' });
		} catch (error) {
			genericExceptionHandler(error, () => { });
		}
	};
};
