import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { InfoCard, Link } from '@dtsl/react';
import { closeBanner } from '../../actions/bannerNotification';
import { checkShowNotificationBanner, getBannerInfo } from './helper';
import { BannerSourcePricing } from './constants';

const ctaMapping = {
	due_invoice: '/billing/invoices',
	subscription_suspension_offline: '/billing/invoices',
	due_invoice_offline: '/billing/invoices',
	payment_method_expired: '/billing/payment-methods',
	payment_method_about_to_expire: '/billing/payment-methods',
	subscription_paused: '/billing/invoices'
};

function BannerNotification() {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const [showBanner, setshowBanner] = useState(checkShowNotificationBanner(location.pathname));

	useEffect(() => {
		setshowBanner(checkShowNotificationBanner(location.pathname));
	}, [location.pathname]);

	const {
		source,
		banner_type,
		variant,
		data,
		meta_data = {}
	} = useSelector((state) => state?.bannerNotification?.bannerInfo) || {};

	if (source !== BannerSourcePricing) return null;

	const translationKey = getBannerInfo(banner_type, data?.plan_expired_date, meta_data?.pending_invoices);

	const handleClose = () => {
		dispatch(closeBanner());
	};

	const args = {
		variant,
		closable: true,
		onClose: handleClose,
		content: (
			<div>
				<FormattedMessage
					id={translationKey}
					values={{
						a: (chunk) => (
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<Link
								href="#"
								onClick={() => history.push(ctaMapping[banner_type])}
								labelText={chunk}
								className="bold"
							/>
						)
					}}
				/>
			</div>
		)
	};

	return (showBanner && translationKey) ? (
		<div className="banner-notification">
			<InfoCard {...args} />
		</div>
	) : <></>;
}

export default BannerNotification;
