import { combineReducers } from 'redux';
import { invoicesApiSlice } from '@dtsl/common-billing-components/src/Invoices/slices/apiSlice';
import { creditNotesApiSlice } from '@dtsl/common-billing-components/src/CreditNotes/slices/apiSlice';
import billingInformation from '@dtsl/common-billing-components/src/BillingInformation/reducer';
import myPaymentMethods from '@dtsl/common-billing-components/src/PaymentMethods/reducer';
import accounts from './accounts';
import corpAccountInfo from './corpAccountInfo';
import plan from './plan';
import profile from './profile';
import utilData from './utilData';
import pagination from './pagination';
import customizePlan from './customizePlan';
import masterInvitedUsers from './masterInvitedUsers';
import subaccInvitedUsers from './subaccInvitedUsers';
import saml from './saml';
import analytics from './analytics';
import apiKeysReducer from '../components/api-keys/reducer';
import modalReducer from '../components/modals/reducer';
import ipManagement from './ipManagement';
import notification from './notifications';
import appManagement from './appManagement';
import organizationsList from './organizationsList';
import groupManagement from './groupManagement';
import bannerNotification from './bannerNotification';

const reducers = combineReducers({
	plan,
	accounts,
	ipManagement,
	appManagement,
	corpAccountInfo,
	profile,
	[invoicesApiSlice.reducerPath]: invoicesApiSlice.reducer,
	[creditNotesApiSlice.reducerPath]: creditNotesApiSlice.reducer,
	billingInformation,
	myPaymentMethods,
	pagination,
	utilData,
	customizePlan,
	masterInvitedUsers,
	subaccInvitedUsers,
	saml,
	analytics,
	apiKeysReducer,
	modalReducer,
	notification,
	organizationsList,
	groupManagement,
	bannerNotification,
});

export default reducers;
