import React from 'react';
import PropTypes from 'prop-types';
import { ActionList, Tag, ActionListOptionTypes } from '@dtsl/react';
import { useIntl } from 'react-intl';
import HeaderIcons from '../SVG/HeaderIcons';
import icons from '../../constants/headerIcons';
import URLS from '../../utils/urlHelper';

const {
	HELP_ICON,
	RESOURCES_ICON,
	DOCUMENTATION_ICON,
	SUPPORT,
} = icons;

function HelpDropdown({ locale }) {
	const { messages } = useIntl();
	const actionListData = [
		{
			type: ActionListOptionTypes.SECTION_LIST,
			items: [
				{
					id: 'header.help.support',
					title: messages['header.help.support'],
					icon: () => <HeaderIcons iconType={SUPPORT} />,
					onSelect: () => window.open(`${URLS.ACCOUNT_APP_URL}/account/zendesk-support`, '_blank')
				},
				{
					id: 'header.help.resources',
					title: messages['header.help.resources'],
					icon: () => <HeaderIcons iconType={RESOURCES_ICON} />,
					onSelect: () => window.open(`https://help.brevo.com/hc/${locale}`, '_blank')
				},
				{
					id: 'header.help.api',
					title: messages['header.help.api'],
					icon: () => <HeaderIcons iconType={DOCUMENTATION_ICON} />,
					onSelect: () => window.open(`https://developers.${APP_DOMAIN}.com/?lang=${locale}`, '_blank')
				},
			],
		}];

	const triggerButtonMetaData = {
		iconAlone: true,
		variant: 'tertiary',
		icon: <HeaderIcons iconType={HELP_ICON} />,
		ariaLabel: 'action-list'
	};
	return (
		<div>
			<ActionList
				triggerButtonMetaData={triggerButtonMetaData}
				options={actionListData}
				placement="bottomRight"
				className="help__action-list"
			/>
		</div>
	);
}

HelpDropdown.propTypes = {
  locale: PropTypes.string
};

export default HelpDropdown;
