import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { APPLICATION_TITLE } from '../../constants/common';

// custom hook to modify the page's title
function useModifyAppTitle() {
	const location = useLocation();
	useEffect(() => {
		// setInterval is needed due to dynamic headings
		const intervalId = setInterval(() => {
			const allHTags = [];

			// fetch all h1-h6 tags
			new Array(6).fill('h').forEach((tag, index) => {
				const hTag = tag + (index + 1);
				allHTags.push(...document.body.getElementsByTagName(hTag));
			});

			// Set the title as the 1st header's text or just Brevo
			const firstHTag = allHTags[0];
			if (firstHTag) {
				document.title = firstHTag.innerText
					? `${firstHTag.innerText} - ${APPLICATION_TITLE}`
					: APPLICATION_TITLE;
				clearInterval(intervalId);
			} else {
				document.title = APPLICATION_TITLE;
			}
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	});
}

export default useModifyAppTitle;
