import {
	FETCH_INVITED_USERS,
	FETCHING_INVITED_USERS,
	FETCH_INVITED_USERS_PERMISSIONS,
	FETCHING_INVITED_USERS_PERMISSIONS,
	ADDING_NEW_INVITED_USER,
	ALL_RESOURCES_ACCESS,
	FETCH_EDIT_INVITED_USERS,
	FETCHING_EDIT_INVITED_USERS,
	EDITING_INVITED_USERS,
	FETCH_RESOURCES_LIST
} from '../actions/actionTypes';

const initialState = {
	fetchingInvitedUsers: false,
	fetchingInvitedUsersPermissions: false,
	fetchingExistingInvitedUser: false,
	existingInvitedUser: {},
	editingExistedUser: false,
	addingNewInvitedUser: false,
	allResourcesAccess: true,
	resourcesList: [],
	invitedUsers: {},
	invitedUsersPermissions: {},
};

const masterInvitedUsers = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_INVITED_USERS:
			return { ...state, fetchingInvitedUsers: action.payload };
		case FETCH_INVITED_USERS:
			return { ...state, invitedUsers: action.payload };
		case FETCHING_INVITED_USERS_PERMISSIONS:
			return { ...state, fetchingInvitedUsersPermissions: action.payload };
		case FETCH_INVITED_USERS_PERMISSIONS:
			return { ...state, invitedUsersPermissions: action.payload };
		case ADDING_NEW_INVITED_USER:
			return { ...state, addingNewInvitedUser: action.payload };
		case ALL_RESOURCES_ACCESS:
			return { ...state, allResourcesAccess: action.payload };
		case FETCHING_EDIT_INVITED_USERS:
			return { ...state, fetchingExistingInvitedUser: action.payload };
		case FETCH_EDIT_INVITED_USERS:
			return { ...state, existingInvitedUser: action.payload };
		case EDITING_INVITED_USERS:
			return { ...state, editingExistedUser: action.payload };
		case FETCH_RESOURCES_LIST:
			return { ...state, resourcesList: action.payload };

		default:
			return state;
	}
};

export default masterInvitedUsers;
