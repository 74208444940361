import {
	ADDING_ACCOUNT,
	FETCH_ACCOUNT_LIST,
	FETCHING_ACCOUNT_LIST,
	FETCH_NEW_ACCOUNT_PLAN,
	RESET_SUB_ACCOUNT_LIST,
	LOGGING_IN_SUBACCOUNT,
	FETCH_ALL_SUB_ACCOUNTS,
	FETCHING_ALL_SUB_ACCOUNTS,
	ADD_FILTERED_SUBACCOUNT_IDS,
	ALLOCATING_CONSUMABLES,
	SELECTED_SUBACCOUNTS,
	SUBACCOUNT_NAME,
	UPDATING_CONSUMABLES,
	SET_CURRENT_SUBACCOUNT_IDS,
	MODIFY_ACTIVATED_APPSLIST_V2,
	SET_ACTIVATED_APPS,
	FETCH_SUBORGANIZATION_SETTINGS,
	FETCHING_SUBORGANIZATION_SETTINGS,
	UPDATING_SUBORGANIZATION_SETTINGS,
	FETCHING_GROUPS_FOR_SUBACCOUNT,
	FETCH_GROUPS_FOR_SUBACCOUNT
} from '../actions/actionTypes';
import { getModifiedActivatedAppsList_v2 } from '../components/appManagement/helper';

const initialState = {
	subAccountCount: null,
	subAccountList: {},
	currentSubaccountIds: [],
	activatedApps: {},
	allSubAccountList: [],
	filteredSubAccountIds: [],
	selectedSubaccounts: [],
	fetchingAllSubaccounts: false,
	fetchAllSubaccountsError: false,
	fetchingAccountList: false,
	addingAccount: false,
	loggingIn: false,
	allocatingConsumables: false,
	updatingConsumables: false,
	subaccountName: '',
	fetchingSubOrganizationSettings: false,
	subOrganizationSettingsData: {},
	updatingSubOrganizationSettings: false,
	fetchingGroupsForSubaccountCreation: false,
	groupsForSubaccountCreation: []
};

function accounts(state = initialState, action) {
	switch (action.type) {
		case ADDING_ACCOUNT:
			return { ...state, addingAccount: action.payload };
		case ALLOCATING_CONSUMABLES:
			return { ...state, allocatingConsumables: action.payload };
		case UPDATING_CONSUMABLES:
			return { ...state, updatingConsumables: action.payload };
		case FETCH_ACCOUNT_LIST: {
			return { ...state, ...action.payload };
		}
		case SET_CURRENT_SUBACCOUNT_IDS:
			return { ...state, currentSubaccountIds: action.payload };
		case FETCHING_ACCOUNT_LIST:
			return { ...state, ...action.payload };
		case FETCH_NEW_ACCOUNT_PLAN:
			return { ...state, newAccountPlan: action.payload };
		case LOGGING_IN_SUBACCOUNT:
			const { subAccountId, loggingIn } = action.payload;
			state.subAccountList.byId[subAccountId].loggingIn = loggingIn;
			return { ...state };
		case RESET_SUB_ACCOUNT_LIST:
			return { ...state, subAccountList: {}, subAccountCount: null };
		case FETCH_ALL_SUB_ACCOUNTS:
			return { ...state, allSubAccountList: action.payload };
		case FETCHING_ALL_SUB_ACCOUNTS:
			const { isFetching, isError } = action.payload;
			return { ...state, fetchingAllSubaccounts: isFetching, fetchAllSubaccountsError: isError };
		case ADD_FILTERED_SUBACCOUNT_IDS:
			return { ...state, filteredSubAccountIds: action.payload };
		case SELECTED_SUBACCOUNTS:
			return { ...state, selectedSubaccounts: action.payload };

		case SUBACCOUNT_NAME:
			return { ...state, subaccountName: action.payload };

		case SET_ACTIVATED_APPS:
			state.activatedApps = action.payload;
			return { ...state };

		case MODIFY_ACTIVATED_APPSLIST_V2:
			state.activatedApps[action.payload.subAccountId] = getModifiedActivatedAppsList_v2(state, action.payload);
			return { ...state };

		case FETCHING_SUBORGANIZATION_SETTINGS:
			return { ...state, fetchingSubOrganizationSettings: action.payload };

		case FETCH_SUBORGANIZATION_SETTINGS:
			return { ...state, subOrganizationSettingsData: action.payload };

		case UPDATING_SUBORGANIZATION_SETTINGS:
			return { ...state, updatingSubOrganizationSettings: action.payload };

		case FETCHING_GROUPS_FOR_SUBACCOUNT:
			return { ...state, fetchingGroupsForSubaccountCreation: action.payload };

		case FETCH_GROUPS_FOR_SUBACCOUNT:
			return { ...state, groupsForSubaccountCreation: action.payload };

		default:
			return state;
	}
}

export default accounts;
