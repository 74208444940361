import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavigationImpl from './NavigationImpl';

// hide Sidebar for the below paths
export const shouldHideSidebar = ({ pathname }) => {
	const sidebarPaths = [
		'/accounts/add',
		'/organization/invitation/send',
		'/add-user',
		'/profile/phone-number/verification',
		'/add-domain',
		'/add-group'
	];
	return sidebarPaths.includes(pathname);
};

function Sidebar({ hideSidebar, setHideSidebar=() => {} }) {
	const location = useLocation();

	const [navData, setNavData] = useState({});

	const {
		fetchingCorpInfo, hasApiGranted, isOwner, language, hasSecurity
	} = useSelector((state) => state.corpAccountInfo);

	useEffect(() => {
		if (fetchingCorpInfo) return;

		const tempNavData = {
			brevo_logo: {
				id: 'brevo_logo',
				name: '',
				urlsMeta: [
				  {
						url: '/',
				  }
				],
			},
			home: {
				id: 'home',
				urlsMeta: [
				  {
						url: '/',
						iconName: 'Home',
				  }
				],
				name: <FormattedMessage id="sidebar.home" />,
			},
		};

		// Analytics
		tempNavData['analytics'] = {
			id: 'analytics',
			urlsMeta: [
				{
					url: '/analytics',
					iconName: 'BarChart2',
				}
			],
			name: <FormattedMessage id="sidebar.analytics" />,
		};

		// Users
		tempNavData['users'] = {
			id: 'users',
			urlsMeta: [
				{
					url: '/organization/invited/users',
					iconName: 'Users',
				}
			],
			name: <FormattedMessage id="sidebar.users" />,
		};

		// Groups listing should always be visible
		tempNavData['groups'] = {
			id: 'groups',
			urlsMeta: [
				{
					url: '/groups',
					iconName: 'Company',
				}
			],
			name: <FormattedMessage id="groupManagement.listing.heading" />,
		};

		// IPs management
		tempNavData['ip_management'] = {
			id: 'ip_management',
			urlsMeta: [
				{
					url: '/ip/domains',
					iconName: 'MapPin',
				}
			],
			name: <FormattedMessage id="sidebar.ips_management" />,
		};

		// Api keys
		tempNavData['api'] = {
			id: 'api',
			urlsMeta: [
				{
					url: '/manage/api',
					iconName: 'Tool',
					meta_data_details: { isLocked: !hasApiGranted }
				}
			],
			name: <FormattedMessage id="sidebar.api" />,
		};

		// Security
		tempNavData['security'] = {
			id: 'security',
			urlsMeta: [
				{
					url: '/saml/configure',
					iconName: 'Shield',
					meta_data_details: { isLocked: !isOwner && !hasSecurity }
				}
			],
			name: <FormattedMessage id="sidebar.security" />,
			popoverMetaData: {
				title: <FormattedMessage id="sidebar.security_permission_title" />,
				offset: [16, 16],
				content: <FormattedMessage id="sidebar.security_permission_popover" />
			  },
		};

		// My plan
		tempNavData['my_plan'] = {
			id: 'my_plan',
			urlsMeta: [
				{
					url: '/billing/my-plan',
					iconName: 'CreditCard',
				}
			],
			name: <FormattedMessage id="sidebar.my_plan" />,
		};

		setNavData(tempNavData);
	}, [fetchingCorpInfo, language]);

	useEffect(() => {
		// Hide or show Sidebar based on the url
		setHideSidebar(shouldHideSidebar(location));
	}, [location.pathname]);

	return (
		<div>
			{!hideSidebar && <NavigationImpl navData={navData} />}
		</div>
	);
}

Sidebar.propTypes = {
	hideSidebar: PropTypes.bool.isRequired,
	setHideSidebar: PropTypes.func
};

export default Sidebar;
