import Cookies from 'js-cookie';
import {
	FETCH_CORP_ACCOUNT_INFO,
	FETCHING_CORP_INFO,
	UPDATE_CORP_INFO
} from '../actions/actionTypes';

const initialState = {
	email: '',
	familyName: '',
	givenName: '',
	accountId: '',
	organizationId: '',
	language: Cookies.get('tmpl_lang') || 'en',
	country: Cookies.get('country') || 'US',
	fetchCorpInfoError: false,
	fetchingCorpInfo: false,
	isAuthenticated: false,
	analytics: 'disabled',
	isEnterpriseV2: false
};

const corpAccountInfo = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CORP_ACCOUNT_INFO:
			return { ...state, ...action.payload };
		case FETCHING_CORP_INFO:
			return { ...state, fetchingCorpInfo: action.payload };
		case UPDATE_CORP_INFO:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default corpAccountInfo;
