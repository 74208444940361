import React from 'react';
import {
	DUE_INVOICE,
	DUE_INVOICE_OFFLINE,
	PAYMENT_METHOD_ABOUT_TO_EXPIRE,
	PAYMENT_METHOD_EXPIRED,
	SUBSCRIPTION_SUSPENSION_OFFLINE,
	SUBSCRIPTION_PAUSED
} from './constants';

export const checkShowNotificationBanner = (currentPath) => {
	const paths = ['/'];
	return paths.includes(currentPath) || currentPath.includes('billing');
};

export const getBannerInfo = (
	banner_type,
	plan_expired_date_obj = {},
	pending_invoices = {}
) => {
	if (banner_type === DUE_INVOICE) {
		const invoices = Object.keys(pending_invoices);
		if (invoices.length) {
			return `bannerNotification.${DUE_INVOICE}.${invoices.length > 1 ? 'multiple' : 'single'}.message`;
		}
	}
	if (banner_type === PAYMENT_METHOD_EXPIRED) {
		return 'bannerNotification.payment_method_expired';
	}
	if (banner_type === PAYMENT_METHOD_ABOUT_TO_EXPIRE) {
		return 'bannerNotification.payment_method_about_to_expire';
	}

	if (banner_type === SUBSCRIPTION_SUSPENSION_OFFLINE) return 'bannerNotification.subscription_suspension_offline';

	if (banner_type === DUE_INVOICE_OFFLINE) return 'bannerNotification.due_invoice_offline';

	if (banner_type === SUBSCRIPTION_PAUSED) return 'bannerNotification.subscription_paused';

	return '';
};
