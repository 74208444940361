import { FETCH_COUNTRIES, FETCH_TIMEZONES } from '../actions/actionTypes';

const initialState = {
	countries: {
		error: false,
		data: []
	},
	timeZones: {
		error: false,
		data: []
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_COUNTRIES:
			return { ...state, countries: action.payload };
		case FETCH_TIMEZONES:
			return { ...state, timeZones: action.payload };
		default:
			return state;
	}
};
