import { UPDATE_SUBACCOUNT_PLAN } from '../actions/actionTypes';

const initialState = {
	updatingPlan: false,
	errors: {}
};

const customizePlan = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SUBACCOUNT_PLAN:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default customizePlan;
