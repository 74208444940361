import {
	FETCH_SUBACC_INVITED_USERS, FETCHING_SUBACC_INVITED_USERS, ASSOCIATE_SUBACCOUNTS, ASSOCIATING_SUBACCOUNTS,
	SENDING_INVITATION, INVITE_AGAIN, INVITING_AGAIN, DELETE_INVITATION, DELETING_INVITATION, REVOKING_ACCESS,
	CANCELING_INVITATION, CANCEL_INVITATION, EDITING_PERMISSIONS, UPDATE_INVITED_USERS, CUSTOM_SELECTED_PERMISSIONS,
	MULTIPLE_TOGGLE_STATE, MODIFY_CONNECTED_USERS, MODIFY_NON_CONNECTED_USERS,
	VALIDATE_INVITEE, VALIDATING_INVITEE, MODIFY_INVITED_USERS,
	FETCHING_SUBORGANIZATIONS_USERS_EMAILS, FETCH_SUBORGANIZATIONS_USERS_EMAILS
} from '../actions/actionTypes';

const initialState = {
	fetchingSubaccInvitedUsers: false,
	associatingSubaccounts: false,
	associatedSubaccounts: {},
	subaccountInvitedUsers: {},
	fetchingSubOrganizationsUsersEmails: false,
	subOrganizationsUsersEmails: [],
	inviteAgainResponse: {},
	deleteInvitationResponse: {},
	cancelInvitationResponse: {},
	personalizedPermissions: [],
	invitingAgain: false,
	deletingInvitation: false,
	sendingInvitation: false,
	cancelingInvitation: false,
	revokingAccess: false,
	editingPermissions: false,
	togglesState: [],
	validingInvitee: false,
	validInvitee: null,
};

const subaccInvitedUsers = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_SUBACC_INVITED_USERS:
			return { ...state, fetchingSubaccInvitedUsers: action.payload };
		case FETCH_SUBACC_INVITED_USERS:
			return { ...state, subaccountInvitedUsers: action.payload };
		case FETCHING_SUBORGANIZATIONS_USERS_EMAILS:
			return { ...state, fetchingSubOrganizationsUsersEmails: action.payload };
		case FETCH_SUBORGANIZATIONS_USERS_EMAILS:
			return { ...state, subOrganizationsUsersEmails: action.payload };
		case ASSOCIATING_SUBACCOUNTS:
			return { ...state, associatingSubaccounts: action.payload };
		case ASSOCIATE_SUBACCOUNTS:
			return { ...state, associatedSubaccounts: action.payload };
		case SENDING_INVITATION:
			return { ...state, sendingInvitation: action.payload };
		case INVITING_AGAIN:
			return { ...state, invitingAgain: action.payload };
		case INVITE_AGAIN:
			return { ...state, inviteAgainResponse: action.payload };
		case DELETING_INVITATION:
			return { ...state, deletingInvitation: action.payload };
		case DELETE_INVITATION:
			return { ...state, deleteInvitationResponse: action.payload };
		case REVOKING_ACCESS:
			return { ...state, revokingAccess: action.payload };
		case CANCELING_INVITATION:
			return { ...state, cancelingInvitation: action.payload };
		case CANCEL_INVITATION:
			return { ...state, cancelInvitationResponse: action.payload };
		case UPDATE_INVITED_USERS:
			return { ...state, associatedSubaccounts: { ...state.associatedSubaccounts, invitations: action.payload } };
		case MODIFY_CONNECTED_USERS:
			return { ...state, associatedSubaccounts: { ...state.associatedSubaccounts, connected: action.payload } };
		case MODIFY_NON_CONNECTED_USERS:
			return {
				...state,
				associatedSubaccounts: { ...state.associatedSubaccounts, non_connected: action.payload }
			};
		case MODIFY_INVITED_USERS:
			return {
				...state,
				associatedSubaccounts: {
					...state.associatedSubaccounts,
					invitations: action.payload
				}
			};
		case CUSTOM_SELECTED_PERMISSIONS:
			return { ...state, personalizedPermissions: action.payload };
		case EDITING_PERMISSIONS:
			return { ...state, editingPermissions: action.payload };
		case MULTIPLE_TOGGLE_STATE:
			return { ...state, togglesState: action.payload };
		case VALIDATING_INVITEE:
			return { ...state, validingInvitee: action.payload };
		case VALIDATE_INVITEE:
			return { ...state, validInvitee: action.payload };
		default:
			return state;
	}
};

export default subaccInvitedUsers;
