import { DUE_INVOICE, DUE_INVOICE_OFFLINE, SUBSCRIPTION_SUSPENSION_OFFLINE } from './constants';

export const checkShowNotificationBanner = (currentPath) => {
	const paths = ['/'];
	return paths.includes(currentPath) || currentPath.includes('billing');
};

export const getBannerInfo = (
	banner_type,
	plan_expired_date_obj = {},
	pending_invoices = {}
) => {
	if (banner_type === DUE_INVOICE) {
		const invoices = Object.keys(pending_invoices);
		if (invoices.length) {
			return `bannerNotification.${DUE_INVOICE}.${invoices.length > 1 ? 'multiple' : 'single'}.message`;
		}
	}
	if (banner_type === SUBSCRIPTION_SUSPENSION_OFFLINE) return 'bannerNotification.subscription_suspension_offline';

	if (banner_type === DUE_INVOICE_OFFLINE) return 'bannerNotification.due_invoice_offline';

	return '';
};
