
/* eslint-disable */

import { Tag, Tooltip } from '@dtsl/react';
import React from 'react';

// Returns headings: IP, Associated domain, DKIM, UsedFor, Sender score, Sender base
export function getIpHeaderKeys(messages) {
	const messagesList = ["ipManagement.ip_listing.ip", "ipManagement.ip_listing.associatedDomain",
							"ipManagement.ip_listing.dkim", "ipManagement.ip_listing.senderScore",
							"ipManagement.ip_listing.senderBase", "ipManagement.ip_listing.usedFor",
						].map(message => messages[message]);

	const maxLengthList = [16, 25, 25, 15, 15, 34];

	return (
		<>
			{messagesList.map ((message, i) => {
				// If messages overflows i.e, if length is greater than specified in maxLengthList
				const triggerEvents =  message?.length > maxLengthList[i] ? ['hover'] : [''];

				return (
					<p key={message}>
						<Tooltip
							text={message}
							placement="bottom"
							trigger={triggerEvents}
							offset={[0, 8]}
						>
							<span>{message}</span>
						</Tooltip>
					</p>
				);
			})}
		</>
	);
}

// Returns values for IP, Associated domain, DKIM, UsedFor, Sender score, Sender base
export function getIpHeaderValues(ip, messages) {
	const notAvailable = messages['ipManagement.notAvailable'];
	const usedFor = ip?.marketing && ip?.transactional ? 'ipManagement.ip_listing.marketingTransactional'
					: ip?.marketing ? 'ipManagement.ip_listing.marketing'
					: 'ipManagement.notAvailable';

	const senderBaseKey = 'ipManagement.ip_listing.senderBase' + ip?.sender?.base;
	// If a translation isn't available for the sender base value, display the raw value. If it is null, display N/A
	const senderBase = ip?.sender?.base ? messages[senderBaseKey] || ip?.sender?.base : notAvailable;

	const ipHeaderValuesList = [
									ip?.ip || notAvailable, ip?.associated_domain || notAvailable,
									ip?.dkim_domain || notAvailable, ip?.sender?.score || notAvailable,
									senderBase,  messages[usedFor],
								];

	const maxLengthList = [16, 23, 23, null, null, 26];
	return (
		<>
			{ipHeaderValuesList.map((value, i) => {

				// If values overflow i.e, if length is greater than specified in maxLengthList
				const triggerEvents =  maxLengthList[i] && value?.length > maxLengthList[i] ? ['hover'] : [''];

				return (
					<p key={i}>
						 <Tooltip
							text={value+''}
							placement="bottom"
							trigger={triggerEvents}
							offset={[0, 8]}
						>
							{i === 3 ? <Tag label={value} color='green'/>
										: <span>{value}</span>
							}
						</Tooltip>
					</p>
				)
			})}
		</>
	);
}

// Check if the subaccount is already assigned to the IP.
// If yes, it shouldn't be shown in the Assign to Subaccount modal
export const isSubaccountAssigned = (subacc, assignedSubaccounts=[]) => {
	for (const assignedSubaccount of Object.values(assignedSubaccounts)) {
		// subaccounts fetched from /api/sub-account/all have the key organization_mysql_id (organizationMysqlId)
		if (subacc?.organizationMysqlId === assignedSubaccount?.organization_id) {
			return false;
		}
	}
	return true;
};

// Return the IP list after a subaccount has been removed from an IP or an IP has been assigned to multiple subaccounts
// This is to avoid calling the fetchIPs API again
export const getUpdatededIpsList = (state, ipAddress, data, action) => {
	for (let i=0;i<state?.updatedIpsList.length;i++) {
		if (state?.updatedIpsList[i]?.ip === ipAddress) {
			if (action === 'remove') {
				const organizationId = data;
				for (let j=0;j<state?.updatedIpsList[i]?.sub_accounts.length;j++) {
					if (state?.updatedIpsList[i]?.sub_accounts[j]?.organization_id === organizationId) {
						state?.updatedIpsList[i]?.sub_accounts.splice(j, 1);
					}
				}
			} else if (action === 'assign') {
				const subaccountsList = data;
				subaccountsList.forEach(subaccount => {
					subaccount.ip_owner = false;

					// The ip?.sub_accounts[] list has the key organization_id but with value of organizationMysqlId
					subaccount.organization_id = subaccount.organizationMysqlId;
					// Remove the organizationMysqlId key as it is unnecessary for listing of the subaccounts under the IP.
					const {organizationMysqlId, ...rest} = subaccount;
					state?.updatedIpsList[i]?.sub_accounts.push(rest);
				});
			}
			return state.updatedIpsList;
		}
	}
};

// Check if the IP is already assigned to the subaacount.
// If yes, it shouldn't be shown in the Assign IPs to Subaccount modal
export const isIpUnAssigned = (ip, assignedIps) => {
	for (const i in assignedIps) {
		if (ip === assignedIps[i]) {
			return false;
		}
	}
	return true;
};

// Return the Assigned IP list after IPs have been removed or added to a subaccount
// This also updates the allIpsList so that if user navigates to the IP tab, the data is still relevant
// These help to avoid calling the fetchAssignedIps & master fetchIPs API again
export const getUpdatedAssignedIps = (state, { subaccount, data, allSubAccountList, operation, isEnterpriseV2}) => {

	// Get the Assigned IPs list[] for the subaccount
	const assignedIpsList = state?.assignedIps?.[subaccount.subAccountId] || [];

	if (operation === 'assign') {

		// If there's no assigned IP for a subaccount, the entry in the assignedIps will be absent
		if (!assignedIpsList.length) {
		state.assignedIps[subaccount.subAccountId] = assignedIpsList;
	}
		// Update the FE data of landing page for subaccounts listing that is state.assignedIps
		const toBeAssignedIpsList = data;
		// Add each assigned ip address string
		isEnterpriseV2 ?
		toBeAssignedIpsList.forEach(( ip ) => {
			if(!assignedIpsList.includes(ip)){
			assignedIpsList.push(ip);
			}
		}): toBeAssignedIpsList.forEach(({ ip }) => {
			assignedIpsList.push(ip);
		});

	} else if (operation === 'delete'){
		// Update the FE data of landing page for subaccounts listing that is state.assignedIps
		const deletedIpsList = isEnterpriseV2 ? data : data.map(({ ip }) => ip);
		deletedIpsList.forEach(deletedIp => {
			assignedIpsList.forEach((ip, j) => {
				if (deletedIp === ip) {
					assignedIpsList.splice(j, 1);
				}
			})
		});
	}
	return state.assignedIps;
}

// deleteSubaccountsKeys will make sure the API is called for fetching the sub_accounts when the ip Accordian is clicked
export const deleteSubaccountsKeys = (state) => {
	Object.values(state?.ipsCollection).forEach(ip => {
		delete ip?.sub_accounts;
	});
	return state.ipsCollection;
}

// Get list of unOwned IPs for a subaccount
export const getUnOwnedIpsList = (ipsList, subAccountId) => {
	const unOwnedIpsList = ipsList.filter(ipObject => {
		return !isSubaccountIpOwner(ipObject, subAccountId);
	});

	return unOwnedIpsList;
}

// Check if a subaccount is owner of the IP
export const isSubaccountIpOwner = (ipObject, subAccountId) => {
	if (!ipObject.associated_domain) {
		// This will be the case when the IP address isn't listed in the IP master list but the subaccount had it
		return false;
	}
	return ipObject?.ip_owner === subAccountId;
};

export const setLocalStorageDomainData = (data) => {
	window.localStorage.setItem('domainData', JSON.stringify(data));
};

export const getLocalStorageDomainData = () => {
	return JSON.parse(localStorage.getItem('domainData'));
};

