import {
	FETCH_ANALYTICS_DASHBOARD_URL,
	FETCH_ANALYTICS_DASHBOARDS, FETCHING_ANALYTICS_DASHBOARDS, RESET_ANALYTICS_DASHBOARD_URL
} from '../actions/actionTypes';

const initialState = {
	dashboards: {},
	fetchingAnalyticsDashboard: false,
	dashboardUrl: '',
};

function analytics(state = initialState, action) {
	switch (action.type) {
		case FETCH_ANALYTICS_DASHBOARDS:
			return { ...state, dashboards: action.payload };
		case FETCHING_ANALYTICS_DASHBOARDS:
			return { ...state, fetchingAnalyticsDashboard: action.payload };
		case FETCH_ANALYTICS_DASHBOARD_URL:
			return { ...state, dashboardUrl: action.payload };
		case RESET_ANALYTICS_DASHBOARD_URL:
			return { ...state, dashboardUrl: '' };
		default:
			return state;
	}
}

export default analytics;
