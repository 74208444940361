import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar, snackbarConstants } from '@dtsl/react';
import { useIntl } from 'react-intl';
import Header from './header';
import { SUCCESS, ERROR, INFO } from '../constants/notification';
import { removeNotificationPayload } from '../utils/helper';
import BannerNotification from './banner/BannerNotification';
import { fetchNotification } from '../actions/bannerNotification';
import useModifyAppTitle from './hooks/useModifyAppTitle';

function Layout({ children }) {
	const { type, formattedMessageId, messageParams, showNotification } = useSelector(
		(state) => state?.notification
	);
	const snackbar = useSnackbar();
	const intl = useIntl();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchNotification());
	}, []);

	useModifyAppTitle();

	useEffect(() => {
		if (showNotification) {
			const customKey = !formattedMessageId.includes('.')
				? `notification.${type}.${formattedMessageId}` : formattedMessageId;
			const id = snackbar({
				variant: 'default',
				type: snackbarConstants[type],
				message: intl.formatMessage({ id: customKey }, messageParams),
				duration: 5000,
				loading: false,
				showCloseButton: true,
				handleClose: () => {},
			});

			type === SUCCESS
				&& Promise.resolve(
					snackbar({
						id,
						type: snackbarConstants.SUCCESS
					})
				);

			type === INFO
				&& Promise.resolve(
					snackbar({
						id,
						type: snackbarConstants.INFO
					})
				);

			type === ERROR
				&& Promise.reject(
					snackbar({
						id,
						type: snackbarConstants.ERROR
					})
				).catch(() => {});
			dispatch(removeNotificationPayload());
		}
	}, [showNotification]);

	return (
		<>
			<Header />
			<BannerNotification />
			<div className="page__strut" data-testid="layout">
				<main className="page__content">
					{children}
				</main>
			</div>
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.element.isRequired
};

export default Layout;
