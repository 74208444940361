import { ElasticManager } from '@dtsl/jsutils';
import { initialiseGTM } from '../entPricing-components/helper';
import axios from './axios';
import { FETCH_CORP_ACCOUNT_INFO, FETCHING_CORP_INFO, UPDATE_CORP_INFO } from './actionTypes';
import { showNotificationPayload } from '../utils/helper';
import { ERROR, SUCCESS, WARNING } from '../constants/notification';

const getCorpAccountInfoAction = (data) => {
	const {
		data: {
			email,
			user_id,
			name: {
				family_name: familyName,
				given_name: givenName
			} = {},
			localization: {
				timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
				type: localizationType
			},
			account_id: accountId,
			organization_id: organizationId,
			organization_name: organizationName,
			has_pending_profile: hasPendingProfile,
			session_id: sessionId,
			directDebitAllowed,
			analytics,
			hasApiGranted,
			canManageOrganizationMembers,
			isOwner,
			canAccessMyPlan,
			canManageApps,
			hasMultiOrganization,
			isGroupUser,
			canCreateGroups,
			canEditDeleteGroups,
			canCreateSubOrganizations,
			canManageSubOrganizations,
			permissions: {
				security: hasSecurity = false
			} = {},
			isEnterpriseV2 = false
		}
	} = data;

	if (APP_ENV === 'production') {
		ElasticManager.methodInitiator('setUserContext', [{ id: user_id, email }]);
		ElasticManager.methodInitiator('setCustomContext', [{ session: sessionId }]);
	}
	initialiseGTM(isEnterpriseV2, user_id, email, accountId);

	return {
		type: FETCH_CORP_ACCOUNT_INFO,
		payload: {
			email,
			familyName,
			givenName,
			timezone,
			localizationType,
			accountId,
			organizationId,
			organizationName,
			hasPendingProfile,
			directDebitAllowed,
			isAuthenticated: true,
			fetchingCorpInfo: false,
			analytics,
			hasApiGranted,
			canManageOrganizationMembers,
			isOwner,
			canAccessMyPlan,
			canManageApps,
			hasMultiOrganization,
			isGroupUser,
			canCreateGroups,
			canEditDeleteGroups,
			canCreateSubOrganizations,
			canManageSubOrganizations,
			hasSecurity,
			isEnterpriseV2
		}
	};
};

export const fetchingCorpInfo = (flag) => {
	return {
		type: FETCHING_CORP_INFO,
		payload: flag
	};
};

export const fetchCorpAccountInfo = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchingCorpInfo(true));

			const { data } = await axios.get(`${CORPORATE_URL}/api/corporate`);
			const { flashes } = data;

			dispatch(getCorpAccountInfoAction(data));

			if (Object.keys(flashes)) {
				const type = flashes.success ? SUCCESS : flashes.error ? ERROR : WARNING;
				let message = flashes[Object.keys(flashes)][0];
				// Hack to display the message as it is from the API
				if (!message?.includes('.')) message += '.';
				dispatch(showNotificationPayload(type, message));
			}
		} catch (error) {
			const { response: { status } = 500 } = error;
			const isAuthenticated = status !== 401 && status !== 403;
			dispatch({
				type: FETCH_CORP_ACCOUNT_INFO,
				payload: { fetchCorpInfoError: true, isAuthenticated }
			});
			dispatch(fetchingCorpInfo(false));
		}
	};
};

export const updateCorpInfo = (info) => {
	return {
		type: UPDATE_CORP_INFO,
		payload: info
	};
};
