import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { creditNotesApiSlice } from '@dtsl/common-billing-components/src/CreditNotes/slices/apiSlice';
import { invoicesApiSlice } from '@dtsl/common-billing-components/src/Invoices/slices/apiSlice';

import reducers from '../reducers';

const middlewares = [thunk];

// common-billing middlewares below
middlewares.push(creditNotesApiSlice.middleware, invoicesApiSlice.middleware);

const composeEnhancers = APPLICATION_ENV !== 'production'
	&& typeof window === 'object'
	&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
	: compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const configureStore = () => {
	return createStore(reducers, enhancer);
};

export default configureStore();
