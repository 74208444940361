export default {
	ORGANIZATION_LOGO: 'organizationlogo',
	HELP_ICON: 'helpIcon',
	RESOURCES_ICON: 'resourcesIcon',
	DOCUMENTATION_ICON: 'documentationIcon',
	PROFILE_ICON: 'profileIcon',
	PLAN_ICON: 'planIcon',
	SWITCH_ACCOUNT_ICON: 'switchAccountIcon',
	LOGOUT_ICON: 'logoutIcon',
	SUPPORT: 'supportIcon',
	USERS_ICON: 'userIcon',
	SHIELD: 'shield',
	LOCK_ICON: 'lockIcon',
	API_KEYS: 'apiKeys',
	GROUPS: 'groups',
};
