// Notification type
export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';

export const ERROR_RESPONSE = 'errorResponse';
export const SUCCESS_RESPONSE = 'successResponse';

// API Response keys
export const UNKNOWN_ERROR = 'unknown_error';
export const SESSION_EXPIRED = 'session_expired';
