import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActionList, ActionListOptionTypes } from '@dtsl/react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ChevronDown, ChevronUp } from '@dtsl/icons';
import HeaderIcons from '../SVG/HeaderIcons';
import icons from '../../constants/headerIcons';
import { capitalizeFirstChar } from '../../utils/helper';
import URLS from '../../utils/urlHelper';

const {
	PROFILE_ICON,
	PLAN_ICON,
	SWITCH_ACCOUNT_ICON,
	LOGOUT_ICON,
	USERS_ICON,
	SHIELD,
	LOCK_ICON,
	API_KEYS,
	GROUPS,
} = icons;

function NavigationDropdown({ isFetching, email, organizationName,
	hasApiGranted, isOwner, hasSecurity }) {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const { messages } = useIntl();
	const history = useHistory();
	const actionListBodyRef = useRef();
	const [actionListData] = useState([
		{
			type: ActionListOptionTypes.SECTION_LIST,
			items: [
				{
					id: 'email',
					title: '',
					subText: <span className="email">{email}</span>,
					disabled: true,
				},
				{
					id: 'header.organization.profile',
					title: messages['header.organization.profile'],
					icon: () => <HeaderIcons iconType={PROFILE_ICON} />,
					onSelect: () => { setIsDropdownVisible(false); history.push('/profile/information'); }
				},
				{
					id: 'header.organization.plan',
					title: messages['header.organization.plan'],
					icon: () => <HeaderIcons iconType={PLAN_ICON} />,
					onSelect: () => { setIsDropdownVisible(false); history.push('/billing/my-plan'); }
				}]
		},
		{
			type: ActionListOptionTypes.SECTION_LIST,
			items: [
				{
					id: 'groupManagement.listing.heading',
					title: messages['groupManagement.listing.heading'],
					icon: () => <HeaderIcons iconType={GROUPS} />,
					onSelect: () => { setIsDropdownVisible(false); history.push('/groups'); }
				},
				{
					id: 'apiKeys.heading',
					title: messages['apiKeys.heading'],
					icon: () => <HeaderIcons iconType={hasApiGranted ? API_KEYS : LOCK_ICON} />,
					disabled: !hasApiGranted,
					onSelect: () => { setIsDropdownVisible(false); history.push('/manage/api'); }
				},
				{
					id: 'header.organization.users',
					title: messages['header.organization.users'],
					icon: () => <HeaderIcons iconType={USERS_ICON} />,
					onSelect: () => { setIsDropdownVisible(false); history.push('/organization/invited/users'); }
				},
				{
					id: 'security.heading',
					title: messages['security.heading'],
					icon: () => (
							<HeaderIcons iconType={isOwner || hasSecurity ? SHIELD : LOCK_ICON} />
					),
					disabled: !isOwner && !hasSecurity,
					onSelect: () => { setIsDropdownVisible(false); history.push('/saml/configure'); }
				}
			]
		},
		{
			type: ActionListOptionTypes.SECTION_LIST,
			items: [
				{
					id: 'header.organization.switch',
					title: messages['header.organization.switch'],
					icon: () => <HeaderIcons iconType={SWITCH_ACCOUNT_ICON} />,
					onSelect: () => { setIsDropdownVisible(false); window.location.href = `${URLS.ACCOUNT_APP_URL}/account/session/organization`; }
				},
				{
					id: 'header.organization.logout',
					title: messages['header.organization.logout'],
					icon: () => <HeaderIcons iconType={LOGOUT_ICON} />,
					onSelect: () => { setIsDropdownVisible(false); window.location.href = `${URLS.ACCOUNT_APP_URL}/account/logout`; }
				}
			]
		}
	]);


	const handleDocumentClick = (event) => {
		if (actionListBodyRef.current) {
			if (!actionListBodyRef.current.contains(event.target)) {
				setIsDropdownVisible(false);
			}
		}
	};

	// Check if the user has clicked outside the dropdown. If yes, the ActionList gets closed hence we need to change the Chevron icon as well
	useEffect(() => {
		document.addEventListener('click', handleDocumentClick);
		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, []);

	const triggerButtonMetaData = {
		iconAlone: true,
		variant: 'tertiary',
		icon: <div className="profile-icon">
				<span>{!isFetching && (capitalizeFirstChar(organizationName) || messages["header.organization.default_name"])}</span>
				{isDropdownVisible ? <ChevronUp /> : <ChevronDown />}
        	</div>,
		ariaLabel: 'action-list',
		onClick: () => setIsDropdownVisible((val) => !val)
	};

	return (
		<div ref={actionListBodyRef}>
			<ActionList
				triggerButtonMetaData={triggerButtonMetaData}
				options={actionListData}
				placement="bottomRight"
				className="navigation__action-list"
			/>
		</div>
	);
}

export default NavigationDropdown;

NavigationDropdown.propTypes = {
	isFetching: PropTypes.bool.isRequired,
	email: PropTypes.string.isRequired,
	organizationName: PropTypes.string.isRequired,
	hasApiGranted: PropTypes.bool.isRequired,
	isOwner: PropTypes.bool.isRequired,
	hasSecurity: PropTypes.bool.isRequired
};
