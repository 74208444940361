import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar, snackbarConstants } from '@dtsl/react';
import { useIntl } from 'react-intl';
import Sidebar, { shouldHideSidebar } from './header/Sidebar';
import { SUCCESS, ERROR, INFO } from '../constants/notification';
import { removeNotificationPayload } from '../utils/helper';
import { fetchIps } from '../actions/ipManagement';
import GlobalHeaderImpl from './header/GlobalHeaderImpl';
import { fetchNotification } from '../actions/bannerNotification';
import BannerNotification from './banner/BannerNotification';
import useModifyAppTitle from './hooks/useModifyAppTitle';

function Layout({ children }) {
	const { type, formattedMessageId, messageParams, showNotification } = useSelector(
		(state) => state?.notification
	);
	const location = useLocation();
	const [hideSidebar, setHideSidebar] = useState(shouldHideSidebar(location));

	const snackbar = useSnackbar();
	const intl = useIntl();

	const dispatch = useDispatch();

	useModifyAppTitle();

	useEffect(() => {
		dispatch(fetchNotification());
	}, []);

	useEffect(() => {
		if (showNotification) {
			const customKey = !formattedMessageId.includes('.')
				? `notification.${type}.${formattedMessageId}` : formattedMessageId;
			const id = snackbar({
				variant: 'default',
				type: snackbarConstants[type],
				message: intl.formatMessage({ id: customKey }, messageParams),
				duration: 5000,
				loading: false,
				showCloseButton: true,
				handleClose: () => {},
			});

			type === SUCCESS
				&& Promise.resolve(
					snackbar({
						id,
						type: snackbarConstants.SUCCESS
					})
				);

			type === INFO
				&& Promise.resolve(
					snackbar({
						id,
						type: snackbarConstants.INFO
					})
				);

			type === ERROR
				&& Promise.reject(
					snackbar({
						id,
						type: snackbarConstants.ERROR
					})
				).catch(() => {});
			dispatch(removeNotificationPayload());
		}
	}, [showNotification]);

	return (
		<>
			{/* 1st column in flex */}
			 <Sidebar hideSidebar={hideSidebar} setHideSidebar={setHideSidebar} />

			<div className="entPage__content" data-testid="layout">
				<div style={hideSidebar ? { marginLeft: '-232px' } : {}}>
					<GlobalHeaderImpl hideSidebar={hideSidebar} />
					<BannerNotification />
				</div>
				<main className="page__content">
					{children}
				</main>
			</div>
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.element.isRequired
};

export default Layout;
