import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import history from '@dtsl/common-billing-components/src/Utils/history';
import { SnackbarContainer } from '@dtsl/react';
import authWrapper from './authWrapper';
import SuspenseWrapper from './entPricing-components/SuspenseWrapper';
import Layout from './entPricing-components/Layout';

const HomePage = lazy(() =>
	import(/* webpackChunkName: "rootComp" */ './entPricing-components/HomePage'));
const Accounts = lazy(() =>
	import(/* webpackChunkName: "accountsComp" */ './entPricing-components/accounts'));
const AddAccount = lazy(() =>
	import(/* webpackChunkName: "AddAccount" */ './entPricing-components/accounts/AddAccount/AddAccount'));
const PageNotFound = lazy(() =>
	import(/* webpackChunkName: "PageNotFound" */ './entPricing-components/PageNotFound'));
const ProfileContainer = lazy(() =>
	import(/* webpackChunkName: "ProfileContainer" */ './entPricing-components/profile'));
const BillingContainer = lazy(() =>
	import(/* webpackChunkName: "BillingContainer" */'./entPricing-components/billing/BillingContainer'));
const VerifyPhone = lazy(() =>
	import(/* webpackChunkName: "VerifyPhone" */ './entPricing-components/profile/verifyPhone'));
const ManageSubaccountPlan = lazy(() =>
	import(/* webpackChunkName: 'CustomizePlan' */ './entPricing-components/manageSubaccount'));
const UserManagementContainer = lazy(() =>
	import(/* webpackChunkName: 'userManagement' */'./entPricing-components/accounts/UserManagement'));
const inviteNewUser = lazy(() =>
	import(/* webpackChunkName: 'userManagement' */'./entPricing-components/accounts/InviteNewUser'));
const editInvitedUser = lazy(() =>
	import(/* webpackChunkName: 'userManagement' */'./entPricing-components/accounts/EditInvitedUser'));
const pageAccessDenied = lazy(() =>
	import(/* webpackChunkName: 'pageAccessDenied' */'./entPricing-components/PageAccessDenied'));
const inviteNewSubaccountUser = lazy(() =>
	import(/* webpackChunkName: 'AddNewUser' */
		'./entPricing-components/accounts/UserManagement/SubaccountUserManagement/AddNewUser/InviteNewUser'
	));
const associateSubaccounts = lazy(() =>
	import(/* webpackChunkName: 'AddNewUser' */
		'./entPricing-components/accounts/UserManagement/SubaccountUserManagement/AssociateSubacc'
	));
const Saml = lazy(() =>
	import(/* webpackChunkName: "rootComp" */ './entPricing-components/security/Saml'));
const analyticsContainer = lazy(() =>
	import(/* webpackChunkName: 'analytics' */'./entPricing-components/accounts/Analytics/AnalyticsWrapper'));
const looksWrapper = lazy(() =>
	import(/* webpackChunkName: 'looks' */'./entPricing-components/accounts/Analytics/Looks/LooksWrapper'));
const apiKeys = lazy(() =>
	import(/* webpackChunkName: 'apiKeys' */'./entPricing-components/api-keys/ApiKeysWrapper'));
const IntermediatePaymentPage = lazy(() =>
	import(
		/* webpackChunkName: 'IntermediatePaymentPage' */
		'@dtsl/common-billing-components/src/PaymentMethods/IntermediatePaymentPage'
	));
const IPTabs = lazy(() =>
	import(/* webpackChunkName: 'IPTabs' */ './entPricing-components/IpManagement/IPTabs'));

const AssociateDomain = lazy(() =>
	import(/* webpackChunkName: 'AssociateDomain' */
		'./entPricing-components/IpManagement/IPConfiguration/AssociateDomain'
	));

const ConfigureDomainTabs = lazy(() =>
	import(/* webpackChunkName: 'ConfigureDomainTabs' */
		'./entPricing-components/IpManagement/IPConfiguration/ConfigureDomainTabs'
	));

const AddDomain = lazy(() =>
	import(/* webpackChunkName: 'AddDomain' */
		'./entPricing-components/IpManagement/DomainConfiguration/AddDomain/AddDomain'
	));

const viewConfiguration = lazy(() =>
	import(/* webpackChunkName: 'DomainConfiguration' */
		'./entPricing-components/IpManagement/DomainConfiguration/ViewConfiguration'
	));

const ChangeDomainSignature = lazy(() =>
	import(/* webpackChunkName: 'ChangeDomainSignature' */
		'./entPricing-components/IpManagement/IPConfiguration/ChangeDomainSignature'
	));

const AuthenticateDomain = lazy(() =>
	import(/* webpackChunkName: 'AuthenticateDomain' */
		'./entPricing-components/IpManagement/DomainConfiguration/AuthenticateDomain'
	));

const groupManagementPage = lazy(() =>
	import(
		/* webpackChunkName: 'groupManagementPage' */
		'./entPricing-components/GroupManagement/GroupsListing'
	));

const createGroup = lazy(() =>
	import(
		/* webpackChunkName: 'createGroup' */
		'./entPricing-components/GroupManagement/AddGroup/CreateGroup'
	));

const editGroup = lazy(() =>
	import(
		/* webpackChunkName: 'editGroup' */
		'./entPricing-components/GroupManagement/EditGroup/EditGroupForm'
	));

const addGroupsOnInvite = lazy(() =>
	import(
		/* webpackChunkName: 'addGroupsOnInvite' */
		'./entPricing-components/accounts/InviteNewUser/AddGroupsOnInvite'
	));

function RootEntPricing() {
	import('./assets/less/ent-v2.less');
	const routePaths = [
		{ url: '/', component: Accounts },
		{ url: '/accounts/add', component: AddAccount },
		{ url: '/profile/phone-number/verification', component: VerifyPhone },
		{ url: '/organization/invitation/send', component: inviteNewUser },
		{ url: '/organization/member/role/:id', component: editInvitedUser },
		{ url: '/pageAccessDenied', component: pageAccessDenied },
		{ url: '/add-user', component: inviteNewSubaccountUser },
		{ url: '/user/connect-sub-account', component: associateSubaccounts },
		{ url: '/user/connect-sub-account/:subaccountId', component: associateSubaccounts },
		{ url: '/saml/configure', component: Saml },
		{ url: '/manage/api', component: apiKeys },
		// Remove when @addSender feature goes live
		{ url: '/ip-management/test-add-sender', component: HomePage },
		{ url: '/ip/configuration/associate', component: AssociateDomain },
		{ url: '/ip/configuration/configure', component: ConfigureDomainTabs },
		{ url: '/ip/configuration/edit', component: ConfigureDomainTabs },
		{ url: '/add-domain', component: AddDomain },
		{ url: '/ip/domains/config', component: viewConfiguration },
		{ url: '/ip/configuration/domain-signature', component: ChangeDomainSignature },
		{ url: '/ip/domains/authenticate', component: AuthenticateDomain },
		{ url: '/add-group', component: addGroupsOnInvite }
	];

	// Only owner admin user has permissions to create & modify groups
	const groupManagementPaths = [
		{ url: '/groups', component: groupManagementPage },
		{ url: '/create-group', component: createGroup },
		{ url: '/edit-group', component: editGroup },
	];
	routePaths.push(...groupManagementPaths);

	const profilePages = [
		'/profile/preferences',
		'/profile/password',
		'/profile/information'
	];

	const billingPages = [
		'/billing/my-plan',
		'/billing/payment-methods',
		'/billing/billing-information',
		'/billing/invoices',
		'/billing/stripe/processing'
	];

	const userManagementPages = [
		'/organization/invited/users',
		'/sub-accounts/invited/users'
	];

	const manageSubOrganizationPages = [
		'/sub-account/consumption-limitations',
		'/sub-account/apps',
		'/sub-account/general-settings'
	];

	const analyticsPages = [
		'/analytics',
	];

	const looksPages = [
		'/analytics/looks'
	];

	const intermediateBillingPage = [
		'/billing/payment-methods/add'
	];

	const ipManagementPages = [
		'/ip/assignment',
		'/ip/configuration',
		'/ip/domains',
	];

	return (
		<Router history={history}>
			<Switch>
				<Route
					exact
					path={[...routePaths.map(({ url }) => url),
						...profilePages, ...billingPages, ...userManagementPages,
						 ...analyticsPages, ...looksPages, ...intermediateBillingPage, ...manageSubOrganizationPages,
						 ...ipManagementPages
					]}
				>
					<SnackbarContainer>
						<Layout>

							<SuspenseWrapper>
								<>
									{
										routePaths.map(({ url, component }) => {
											return <Route key={url} path={url} exact component={component} />;
										})
									}
									<Route path={profilePages} exact component={ProfileContainer} />
									<Route path={billingPages} exact component={BillingContainer} />
									<Route path={userManagementPages} exact component={UserManagementContainer} />
									<Route path={analyticsPages} exact component={analyticsContainer} />
									<Route path={looksPages} exact component={looksWrapper} />
									<Route path={intermediateBillingPage} exact component={IntermediatePaymentPage} />
									<Route path={manageSubOrganizationPages} exact component={ManageSubaccountPlan} />
									<Route path={ipManagementPages} exact component={IPTabs} />

								</>
							</SuspenseWrapper>

						</Layout>
					</SnackbarContainer>
				</Route>

				<SuspenseWrapper>
					<PageNotFound />
				</SuspenseWrapper>
			</Switch>
		</Router>
	);
}

export default authWrapper(RootEntPricing);
