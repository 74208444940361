import { HIDE_MODAL, SHOW_MODAL } from './actionTypes';

const initialState = {
	type: null,
	isVisible: false
};

function modalReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				type: action.payload,
				isVisible: true
			};
		case HIDE_MODAL:
			return {
				...state,
				isVisible: false
			};
		default:
			return state;
	}
}

export default modalReducer;
