import { FETCHING_ORGANIZATIONS_LIST, FETCH_ORGANIZATIONS_LIST } from '../actions/actionTypes';

const initialState = {
	fetchingOrganizationsList: false,
	organizationsList: []

};

const organizationsList = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_ORGANIZATIONS_LIST:
			return { ...state, fetchingOrganizationsList: action.payload };
		case FETCH_ORGANIZATIONS_LIST:
			return { ...state, organizationsList: action.payload };

		default:
			return state;
	}
};

export default organizationsList;
