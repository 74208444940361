import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@dtsl/react';
import history from '@dtsl/common-billing-components/src/Utils/history';
import HeaderIcons from '../SVG/HeaderIcons';
import ErrorPageBackground from '../../assets/images/500_illu.png';
import icons from '../../constants/headerIcons';
import { checkKeyIsEnterOrSpace } from '../../utils/helper';
import { APPLICATION_TITLE } from '../../constants/common';

const {
	ORGANIZATION_LOGO,
} = icons;

const Fallback = () => {
	const { messages } = useIntl();
	useEffect(() => {
		document.title = `${messages['errorPage.heading']} - ${APPLICATION_TITLE}`;
	});

	return (
		<>
			<nav
				className="header page__header brevo__background"
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				<span
					className="topnav__control header__organization brevo__background"
					rel="home"
					onClick={() => history.push('/')}
					data-testid="OnClickLogo"
					onKeyDown={({ key }) => checkKeyIsEnterOrSpace(key) && history.push('/')}
					role="button"
					tabIndex="0"
				>
					<HeaderIcons iconType={ORGANIZATION_LOGO} />
				</span>
			</nav>

			<div className="page__strut">
				<main className="page__content">
					<div className="pageNotFoundContainer">
						<section className="col_6">
							<h2 className="error_page_heading">
								<FormattedMessage id="errorPage.heading" />
							</h2>
							<hr className="content__divider" />

							<p className="error_page_contentHeading">
								<FormattedMessage id="errorPage.contentHeading" />
							</p>

							<p className="error_page_content">
								<FormattedMessage
									id="errorPage.content"
									values={
										{
											contactSupportLink: (
												<a
													className="clickable_link clickable_muddy_link"
													href={`${ACCOUNT_URL}/support`}
												>
													<FormattedMessage id="pageNotFound.contactSupport" />
												</a>
											),
											platformLink: (
												<a
													rel="noreferrer"
													href={`http://status.${APP_DOMAIN}.com/`}
													className="clickable_link clickable_muddy_link"
												>
													<FormattedMessage id="pageNotFound.here" />
												</a>
											)
										}
									}
								/>
							</p>

							<div className="error_page_buttons">
								<Button
									label={<FormattedMessage id="button.goToDashboard" />}
									className="dashboard_button"
									data-testid="goToDashboard"
									onClick={() => history.push('/')}
								/>
								<Button
									variant="secondary"
									data-testid="goBack"
									label={<FormattedMessage id="button.goBack" />}
									onClick={history.goBack}
								/>
							</div>
						</section>

						<section className="col_5">
							<img src={ErrorPageBackground} alt="404" style={{ width: '90%' }} />
						</section>
					</div>
				</main>
			</div>
		</>
	);
};

export default Fallback;
