import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCorpAccountInfo } from './actions/corpAccountInfo';
import Loader from './components/common/Loader';

export default (ChildComponent) => {
	class ComposedComponent extends React.Component {
		componentDidMount() {
			!this.props.masterOrganizationId && this.props.fetchCorpAccountInfo();
		}

		render() {
			const { isAuthenticated } = this.props;

			if (isAuthenticated) {
				return <ChildComponent {...this.props} />;
			}

			return (
				<div className="root__loader">
					<Loader />
				</div>
			);
		}
	}

	ComposedComponent.propTypes = {
		fetchCorpAccountInfo: PropTypes.func.isRequired,
		isAuthenticated: PropTypes.bool.isRequired,
		masterOrganizationId: PropTypes.string.isRequired
	};

	const mapStateToProps = ({ corpAccountInfo: { isAuthenticated, organizationId: masterOrganizationId } }) => {
		return { isAuthenticated, masterOrganizationId };
	};

	return connect(mapStateToProps, { fetchCorpAccountInfo })(ComposedComponent);
};
