export const API_KEYS_FETCHING = 'API_KEYS_FETCHING';
export const API_KEYS_RECEIVED = 'API_KEYS_RECEIVED';
export const API_KEYS_FETCH_ERROR = 'API_KEYS_FETCH_ERROR';
export const API_KEY_SELECTED = 'API_KEY_SELECTED';
export const API_KEY_SELECTION_CLEARED = 'API_KEY_SELECTION_CLEARED';
export const API_KEY_ADDED = 'API_KEY_ADDED';
export const API_KEY_DELETED = 'API_KEY_DELETED';
export const API_KEY_ACTION_LOADING = 'API_KEY_ACTION_LOADING';
export const API_KEY_ACTION_ERROR = 'API_KEY_ACTION_ERROR';
export const API_KEY_ACTION_ERROR_RESET = 'API_KEY_ACTION_ERROR_RESET';
export const API_KEYS_IS_BACK_OFFICE = 'API_KEYS_IS_BACK_OFFICE';
