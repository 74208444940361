import { snackbarConstants } from '@dtsl/react';
import axios, { genericExceptionHandler } from './axios';
import { FETCH_ORGANIZATIONS_LIST, FETCHING_ORGANIZATIONS_LIST } from './actionTypes';
import { UNKNOWN_ERROR } from '../constants/notification';

export const errorHandler = ({ dispatch, callback, error, snackbar, intl, errorKey = UNKNOWN_ERROR }) => {
	window.scrollTo(0, 0);

	genericExceptionHandler(error, () => {
		snackbar({
			variant: 'default',
			type: snackbarConstants.ERROR,
			message: intl.formatMessage({ id: `errorResponse.${errorKey}` }),
			duration: 8000,
			loading: false,
			showCloseButton: true,
			handleClose: () => {},
		});
		dispatch(callback(false));
	});
};

export const fetchingOrganizationList = (flag) => {
	return { type: FETCHING_ORGANIZATIONS_LIST, payload: flag };
};

export const fetchOrganizationList = (snackbar, intl) => {
	return async (dispatch) => {
		try {
			dispatch(fetchingOrganizationList(true));

			const { data } = await axios.get(`${CORPORATE_URL}/api/ent-v2/connected-users/`);

			dispatch({
				type: FETCH_ORGANIZATIONS_LIST,
				payload: data
			});

			window.scrollTo(0, 0);
			dispatch(fetchingOrganizationList(false));
		} catch (error) {
			errorHandler({ dispatch, callback: fetchingOrganizationList, error, snackbar, intl });
		}
	};
};
