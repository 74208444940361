/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export const CartSvg = () => {
	return (
		<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
			<path d="M6 18.1l2.1.4L24.9 24v19.9L6 38V18.1zm37.8 0V38l-18.9 6V24.1l18.9-6z" fill="#cedcd9" />
			<path d="M22.9 10.1V29L4 35V16.1l18.9-6zm0 0l18.9 6V35l-18.9-6V10.1z" fill="#091e3f" />
			<path d="M24.9 7.1h15.9V23H24.9z" fill="#b99363" />
			<path d="M14 4.1l9.9 16H4z" fill="#46C4D8" />
			<circle cx="21.9" cy="16.1" r="8" fill="#3c7368" />
			<path d="M4 16.1l2.1.4L22.9 22v18.9L4 35V16.1zm37.8 0V35l-18.9 6V22.1l18.9-6z" fill="#112525" />
		</svg>
	);
};

export const InfoSvg = () => {
	return (
		<svg viewBox="0 0 512 512" className="icon notification__icon">
			<path
				d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083
				   392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200
				   89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42
				   42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627
				   0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12
				   12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
			/>
		</svg>
	);
};

export const SuccessSvg = () => {
	return (
		<svg viewBox="0 0 512 512" className="icon notification__icon">
			<path
				d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0
				   48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532
				   89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346
				   303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736
				   12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668
				   4.734-12.266.067-16.971z"
			/>
		</svg>
	);
};

export const ErrorSvg = () => {
	return (
		<svg viewBox="0 0 512 512" className="icon notification__icon">
			<path
				d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5
				   0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2
				   62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3
				   4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3
				   0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7
				   12.3 0 17z"
			/>
		</svg>
	);
};

export const WarningSvg = () => {
	return (
		<svg viewBox="0 0 512 512" className="icon notification__icon">
			<path
				d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083
				   392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200
				   89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42
				   18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985
				   11.401h41.17c6.394 0 11.666-5.014
				   11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328
				   5.745-11.985 12.599z"
			/>
		</svg>
	);
};

export const LoaderSvg = ({ fill }) => {
	return (
		<svg style={{ fill }} className="progress-indicator__icon" viewBox="0 0 512 512">
			<path
				d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886
				   28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961
				   256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11
				   123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
			/>
		</svg>
	);
};

export const AnglePrevious = () => {
	return (
		<svg viewBox="0 0 192 512">
			<path
				d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7
				   4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z"
			/>
		</svg>
	);
};

export const AngleNext = () => {
	return (
		<svg viewBox="0 0 192 512">
			<path
				d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2
				   136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"
			/>
		</svg>
	);
};

export const Eye = () => {
	return (
		<svg viewBox="0 0 24 24" className="icon icon_standalone">
			<path fill="none" d="M0 0h24v24H0z" />
			<path
				d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11
				   11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66
				   0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
			/>
		</svg>
	);
};

export const PhoneSvg = () => {
	return (
		<svg viewBox="0 0 320 512" className="icon notification__icon">
			<path
				d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5
				   48-48V48c0-26.5-21.5-48-48-48zm-64 452c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12
				   12-12h72c6.6 0 12 5.4 12 12v8zm64-80c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12
				   12-12h200c6.6 0 12 5.4 12 12v312z"
			/>
		</svg>
	);
};

export const CheckSvg = () => {
	return (
		<svg viewBox="0 0 512 512" className="icon icon_standalone clickable__icon">
			<path
				d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284
				   28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971
				   0l299.813-299.813c4.686-4.686 4.686-12.284
				   0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
			/>
		</svg>
	);
};

export const SearchSvg = () => {
	return (
		<svg viewBox="0 0 24 24" className="icon clickable__icon icon_standalone">
			<path
				d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59
				   4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14
				   7.01 14 9.5 11.99 14 9.5 14z"
			/>
			<path fill="none" d="M0 0h24v24H0z" />
		</svg>
	);
};

export const BackSvg = () => {
	return (
		<svg className="icon clickable__icon" viewBox="0 0 612 512">
			<path
				fill="#3c4858"
				d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4
				   24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7
				   24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
			/>
		</svg>
	);
};

export const OptionsSvg = ({ styles }) => {
	return (
		<svg
			className="icon clickable__icon"
			viewBox="0 0 48 48"
			style={styles}
		>
			<path d="M11.5 5.2h28.2V46H11.5z" fill="#cedcd9" />
			<path d="M8.3 2.1h28.3v40.7H8.3z" fill="#112525" />
			<path
				d="M31.9 33.4V35H16.2v-1.6h15.7zm-19.6 0c.5 0
				   .8.3.8.8s-.4.8-.8.8c-.5 0-.8-.3-.8-.8s.3-.8.8-.8zm19.6-4.7v1.6H16.2v-1.6h15.7zm-19.6 0c.5 0
				   .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.3-.8.8-.8zM33.4 24v1.6H16.2V24h17.2zm-21.1 0c.5 0
				   .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.3-.8.8-.8zm16.4-4.7v1.6H16.2v-1.6h12.5zm-16.4 0c.5 0
				   .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.3-.8.8-.8zm19.6-4.7v1.6H16.2v-1.6h15.7zm-19.6 0c.5 0
				   .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.3-.8.8-.8zm21.1-4.7v1.6H16.2V9.9h17.2zm-21.1 0c.5 0
				   .8.3.8.8s-.3.8-.8.8-.8-.3-.8-.8.3-.8.8-.8z"
				fill="#fff"
			/>
		</svg>
	);
};

export const MessageSvg = ({ styles }) => {
	return (
		<svg
			className="icon clickable__icon"
			viewBox="0 0 48 48"
			style={styles}
		>
			<path d="M9.071 12.174H43V40H9.071z" fill="#cedcd9" />
			<path d="M5 8h33.929v27.826H5z" fill="#112525" />
			<path d="M5 8h33.929L21.995 23.304z" fill="#b99363" />
		</svg>
	);
};

LoaderSvg.propTypes = {
	fill: PropTypes.string
};

LoaderSvg.defaultProps = {
	fill: '#B99363'
};

MessageSvg.propTypes = {
	styles: PropTypes.object
};

MessageSvg.defaultProps = {
	styles: {}
};

OptionsSvg.propTypes = {
	styles: PropTypes.object
};

OptionsSvg.defaultProps = {
	styles: {}
};
