import { CHANGE_PAGE, CHANGE_PAGE_LIMIT, RESET_PAGINATION } from '../actions/actionTypes';
import { DEFAULT_LIMIT_PER_PAGE, DEFAULT_PAGE } from '../constants/common';

const initialState = {
	currentPage: DEFAULT_PAGE,
	limitPerPage: DEFAULT_LIMIT_PER_PAGE
};

const pagination = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_PAGE:
			return { ...state, currentPage: action.payload };

		case CHANGE_PAGE_LIMIT:
			return { ...state, ...action.payload };

		case RESET_PAGINATION:
			return { ...state, ...action.payload };

		default:
			return state;
	}
};

export default pagination;
