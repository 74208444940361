import {
	FETCH_SAML_IDENTIFIER, FETCHING_SAML_IDENTIFIER, VERIFYING_SAML_IDENTIFIER,
	SAVING_SETTINGS, UPDATING_SAML_AUTHENTICATION, FETCH_SAML_METADATA
} from '../actions/actionTypes';

const initialState = {
	fetchingSamlIdentifier: false,
	samlIdentifier: {},
	verifyingSamlIdentifier: false,
	savingAllSettings: false,
	updatingSamlAuthentication: false,
	samlMetaData: {}

};

const saml = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_SAML_IDENTIFIER:
			return { ...state, fetchingSamlIdentifier: action.payload };
		case FETCH_SAML_IDENTIFIER:
			return { ...state, samlIdentifier: action.payload };
		case VERIFYING_SAML_IDENTIFIER:
			return { ...state, verifyingSamlIdentifier: action.payload };
		case SAVING_SETTINGS:
			return { ...state, savingAllSettings: action.payload };
		case UPDATING_SAML_AUTHENTICATION:
			return { ...state, updatingSamlAuthentication: action.payload };
		case FETCH_SAML_METADATA:
			return { ...state, samlMetaData: action.payload };

		default:
			return state;
	}
};

export default saml;
