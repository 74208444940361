import { FETCHING_ALL_APPS, FETCH_ALL_APPS, TOGGLING_APP } from '../actions/actionTypes';

const initialState = {
	fetchingAllApps: null,
	allAppsList: null,
	allAppsCollection: null,
	togglingAppsList: [] // List of apps currently being toggled
};
const appManagement = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_ALL_APPS:
			return { ...state, fetchingAllApps: action.payload };
		case FETCH_ALL_APPS:
			const { allAppsList, allAppsCollection } = action.payload;
			return { ...state, allAppsList, allAppsCollection };
		case TOGGLING_APP:
			const { appName, flag } = action.payload;
			if (flag === true) {
				return { ...state, togglingAppsList: [...new Set([...state.togglingAppsList, appName])] };
			}
			return { ...state, togglingAppsList: state.togglingAppsList.filter((app) => app !== appName) };

		default:
			return state;
	}
};

export default appManagement;
