import React from 'react';
import { FormattedMessage } from 'react-intl';
import history from '@dtsl/common-billing-components/src/Utils/history';
import { snackbarConstants } from '@dtsl/react';
import axios, { genericExceptionHandler } from './axios';
import {
	ASSIGNING_SUBACCOUNTS_TO_IP, DISASSOCIATING_IP,
	FETCHING_IPS, FETCH_IPS,
	UPDATE_IP, SET_TOTAL_IPS_COUNT, FETCHING_ASSIGNED_IPS, FETCH_ASSIGNED_IPS,
	ASSIGNING_IPS_TO_SUBACCOUNT, UPDATE_ASSIGNED_IPS, DELETING_IPS_FROM_SUBACCOUNT, ADDING_SENDERS,
	FETCHING_SUBACCOUNTS_FOR_IP, FETCH_SUBACCOUNTS_FOR_IP, REMOVE_SUBACCOUNTS_LIST_FROM_IPS,
	FETCHING_CONFIG_IPS, FETCH_CONFIG_IPS, FETCH_DNS_CONFIG, FETCHING_DNS_CONFIG,
	VALIDATING_IP_RECORDS, VALIDATE_SIMPLE_IP_RECORDS,
	VALIDATE_ADVANCED_IP_RECORDS, ASSOCIATING_DOMAIN, FETCHING_DOMAINS, FETCH_DOMAINS,
	ADDING_DOMAIN, DISSOCIATING_DOMAIN, DELETING_DOMAIN, VALIDATE_DNS_RECORDS,
	FETCHING_DNS_FOR_DOMAIN, FETCH_DNS_FOR_DOMAIN, FETCH_IP_PERMISSIONS, FETCHING_IP_PERMISSIONS,
	TOGGLING_IP_USAGE, AUTHENTICATING_DOMAIN, VALIDATING_DNS_RECORD, CHANGING_DOMAIN_SIGNATURE,
} from './actionTypes';
import { SUCCESS, ERROR, UNKNOWN_ERROR } from '../constants/notification';
import { showNotificationPayload } from '../utils/helper';
import { DOMAINS_PATH, IP_CONFIGURATION_PATH, SIMPLE } from '../entPricing-components/IpManagement/utils/constants';
import { setLocalStorageDomainData, getLocalStorageDomainData } from '../components/IpManagement/utils/helper';
import { SENDERS_IN_USE_ERROR } from '../constants/common';
import URLS from '../utils/urlHelper';

export const errorHandler = ({ dispatch, callback, error, snackbar, intl, errorKey = UNKNOWN_ERROR }) => {
	genericExceptionHandler(error, () => {
		snackbar({
			variant: 'default',
			type: snackbarConstants.ERROR,
			message: intl.formatMessage({ id: `errorResponse.${errorKey}` }),
			duration: 5000,
			loading: false,
			showCloseButton: true,
			handleClose: () => {},
		});
		dispatch(callback(false));
	});
};

// Used to update the IP only in the FE after a subaccount has been removed from an IP or an IP has been added to multiple subaccounts
export const updateIp = (ipAddress, data, operation = 'remove') => {
	return {
		type: UPDATE_IP,
		payload: { ipAddress, data, operation }
	};
};

export const setTotalIpsCount = (totalIpsCount) => {
	return {
		type: SET_TOTAL_IPS_COUNT,
		payload: totalIpsCount
	};
};

export const fetchingIps = (flag) => {
	return {
		type: FETCHING_IPS,
		payload: flag
	};
};

export const fetchIps = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchingIps(true));
			/* eslint-disable-next-line max-len, prefer-const */
			const { data: { data } } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/ip/master`);

			// eslint-disable-next-line no-restricted-syntax
			for (const [key, val] of Object.entries(data)) {
				val.display = true; // Useful for search filter
				if (!val.ip) val.ip = key;
			}

			dispatch(setTotalIpsCount(Object.values(data)?.length));
			dispatch({
				type: FETCH_IPS,
				payload: data
			});

			dispatch(fetchingIps(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR));
				dispatch(fetchingIps(false));
			});
		}
	};
};

export const assigningSubaccountsToIp = (flag) => {
	return {
		type: ASSIGNING_SUBACCOUNTS_TO_IP,
		payload: flag
	};
};

export const assignSubaccountsToIp = (ipAddress, subaccountsList, snackbar, intl, location) => {
	return async (dispatch) => {
		try {
			const organizationIds = subaccountsList.map((subaccount) => subaccount?.organizationMysqlId);
			const payload = {
				ip: ipAddress,
				ids: organizationIds
			};

			dispatch(assigningSubaccountsToIp(true));
			const {
				data: { senders = {} } = {}
			} = await axios.post(`${URLS.IP_MANAGEMENT_API_URL}/ip/sub-account`, payload);

			// The above API gives senders count for each assigned subaccount.
			// As sender name & email aren't available at the moment, add dummy name & email for now.
			subaccountsList.forEach((subaccount) => {
				const sendersCount = senders[subaccount.organizationMysqlId] || 0;
				subaccount.senders = new Array(sendersCount).fill({ name: '', email: '' });
			});
			dispatch(updateIp(ipAddress, subaccountsList, 'assign'));

			dispatch(showNotificationPayload(
				SUCCESS,
				`ipManagement.assign_subaccounts.successMessage${organizationIds.length > 1 ? 'Plural' : ''}`,
				{
					ipAddress,
					subaccountsCount: organizationIds.length,
				}
			));

			dispatch(assigningSubaccountsToIp(false));
		} catch (error) {
			errorHandler({ dispatch, callback: assigningSubaccountsToIp, error, snackbar, intl });
		}
	};
};

export const fetchingSubaccountsForIp = (flag) => {
	return {
		type: FETCHING_SUBACCOUNTS_FOR_IP,
		payload: flag
	};
};

export const fetchSubaccountsForIp = (ipAddress, snackbar, intl) => {
	return async (dispatch) => {
		try {
			dispatch(fetchingSubaccountsForIp(true));
			const { data } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/ip/${ipAddress}/sub-accounts`);
			dispatch({
				type: FETCH_SUBACCOUNTS_FOR_IP,
				payload: { ipAddress, sub_accounts: data }
			});
			dispatch(fetchingSubaccountsForIp(false));
		} catch (error) {
			errorHandler({ dispatch, callback: fetchingSubaccountsForIp, error, snackbar, intl });
		}
	};
};

// This will make sure the API is called for fetching the sub_accounts when the ip Accordian is clicked
export const removeSubaccountsFromIps = () => {
	return {
		type: REMOVE_SUBACCOUNTS_LIST_FROM_IPS
	};
};

export const disassociatingIp = (flag) => {
	return (
		{
			type: DISASSOCIATING_IP,
			payload: flag
		}
	);
};

export const disassociateIp = (organizationId, organizationName, ipAddress, snackbar, intl, location, closeModal) => {
	return async (dispatch) => {
		try {
			const payload = {
				ips: [ipAddress]
			};
			dispatch(disassociatingIp(true));

			await axios.delete(`${URLS.IP_MANAGEMENT_API_URL}/ip/sub-account/${organizationId}`, { data: payload });

			dispatch(updateIp(ipAddress, organizationId, 'remove'));

			snackbar({
				variant: 'default',
				type: snackbarConstants.SUCCESS,
				message: intl.formatMessage({ id: 'ipManagement.remove_subaccount.successMessage' },
					{
						ipAddress,
						subaccount: organizationName,
					}),
				duration: 3000,
				loading: false,
				showCloseButton: true,
				handleClose: () => {},
			});

			dispatch(disassociatingIp(false));
			closeModal();
		} catch (error) {
			const { response: { status, data } = {} } = error;
			if (status === 428 && data?.error?.developer_message === SENDERS_IN_USE_ERROR) {
				const failedIps = data?.error?.data?.ips || [];
				snackbar({
					variant: 'default',
					type: snackbarConstants.ERROR,
					message: (
						<span>
							<FormattedMessage
								id={`errorResponse.${SENDERS_IN_USE_ERROR}`}
								values={{
									b: (chunk) => <b>{chunk}</b>,
									organizationName,
									ipsList: failedIps.join(', ')
								}}
							/>
						</span>
					),
					duration: 8000,
					showCloseButton: true,
				});
				dispatch(disassociatingIp(false));
			} else {
				const errorKey = 'subaccount_dissociation_failed';
				errorHandler({ dispatch, callback: disassociatingIp, error, snackbar, intl, errorKey });
			}
		}
	};
};

export const fetchingAssignedIps = (flag) => {
	return (
		{
			type: FETCHING_ASSIGNED_IPS,
			payload: flag
		}
	);
};

export const fetchAssignedIps = (allIds) => {
	return async (dispatch) => {
		try {
			dispatch(fetchingAssignedIps(true));

			let requiredIds = '';

			for (let i = 0; i < allIds.length; i++) {
				if (i === allIds.length - 1) {
					requiredIds += allIds[i].toString();
				} else {
					requiredIds += `${allIds[i].toString()},`;
				}
			}

			const { data } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/ip/sub-account/${requiredIds}`);

			dispatch({
				type: FETCH_ASSIGNED_IPS,
				payload: data
			});

			window.scrollTo(0, 0);

			dispatch(fetchingAssignedIps(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR));
				dispatch(fetchingAssignedIps(false));
			});
		}
	};
};

export const updateAssignedIps = (subaccount, data, allSubAccountList, operation, isEnterpriseV2) => {
	return {
		type: UPDATE_ASSIGNED_IPS,
		payload: { subaccount, data, allSubAccountList, operation, isEnterpriseV2 }
	};
};

export const assigningIpsToSubaccount = (flag) => {
	return {
		type: ASSIGNING_IPS_TO_SUBACCOUNT,
		payload: flag
	};
};

export const assignIpsToSubaccount = ({ subaccount, ipsList, allSubAccountList,
	 snackbar, intl, isEnterpriseV2, setCheckedIPsList, setIsAssignSuccessful, isDeleteNeeded, closeModal }) => {
	return async (dispatch) => {
		try {
			const ipAddressList = ipsList.map(({ ip }) => ip);
			const payload = {
				ips: isEnterpriseV2 ? ipsList : ipAddressList,
				id: subaccount?.subAccountId
			};

			dispatch(assigningIpsToSubaccount(true));

			await axios.post(`${URLS.IP_MANAGEMENT_API_URL}/ip/sub-account/assign-ips`, payload);

			dispatch(updateAssignedIps(subaccount, ipsList, allSubAccountList, 'assign', isEnterpriseV2));

			snackbar({
				variant: 'default',
				type: snackbarConstants.SUCCESS,
				message: intl.formatMessage({
					id: isEnterpriseV2
					 ? 'successResponse.manage_ips'
					 : `ipManagement.subaccount_page.assign_ip.successMessage${ipsList.length > 1 ? 'Plural' : ''}` },
				{
					IpsCount: ipsList.length,
					subaccount: subaccount?.organizationName
				}),
				duration: 3000,
				loading: false,
				showCloseButton: true,
				handleClose: () => {},
			});

			dispatch(assigningIpsToSubaccount(false));
			setIsAssignSuccessful(true);
			if (!isDeleteNeeded) closeModal();
		} catch (error) {
			setIsAssignSuccessful(false);
			// Remove all the IPs of ipsList from the checkedIPsList so that the corresponding checkboxes are unchecked again
			setCheckedIPsList((val) => val.filter((ip) => !ipsList.includes(ip)));
			errorHandler({ dispatch, callback: assigningIpsToSubaccount, error, snackbar, intl });
		}
	};
};

export const deletingIpsFromSubaccount = (flag) => {
	return {
		type: DELETING_IPS_FROM_SUBACCOUNT,
		payload: flag
	};
};

export const deleteIpsFromSubaccount = ({ subaccount, ipsList, allSubAccountList,
	 snackbar, intl, isEnterpriseV2, setCheckedIPsList, setIsDeleteSuccessful, isAssignNeeded, closeModal }) => {
	return async (dispatch) => {
		try {
			const ipAddressList = ipsList.map(({ ip }) => ip);
			const payload = {
				ips: isEnterpriseV2 ? ipsList : ipAddressList
			};

			dispatch(deletingIpsFromSubaccount(true));

			// eslint-disable-next-line  max-len
			await axios.delete(`${URLS.IP_MANAGEMENT_API_URL}/ip/sub-account/${subaccount?.subAccountId}`, { data: payload });

			dispatch(updateAssignedIps(subaccount, ipsList, allSubAccountList, 'delete', isEnterpriseV2));

			snackbar({
				variant: 'default',
				type: snackbarConstants.SUCCESS,
				message: intl.formatMessage({
					id: `ipManagement.subaccount_page.delete_ip.successMessage${ipsList.length > 1 ? 'Plural' : ''}`
				},
				{
					IpsCount: ipsList.length,
					subaccount: subaccount?.organizationName
				}),
				duration: 3000,
				loading: false,
				showCloseButton: true,
				handleClose: () => { },
			});

			dispatch(deletingIpsFromSubaccount(false));
			if (!isAssignNeeded) closeModal();
			setIsDeleteSuccessful(true);
		} catch (error) {
			setIsDeleteSuccessful(false);
			const { response: { status, data } = {} } = error;
			if (status === 428 && data?.error?.developer_message === SENDERS_IN_USE_ERROR) {
				const failedIps = data?.error?.data?.ips || [];
				// Add back the failed IPs to checkedIPsList so that the corresponding checkboxes are checked again
				setCheckedIPsList((val) => [...new Set([...val, ...failedIps])]);
				snackbar({
					variant: 'default',
					type: snackbarConstants.ERROR,
					message: (
						<span>
							<FormattedMessage
								id={`errorResponse.${SENDERS_IN_USE_ERROR}`}
								values={{
									b: (chunk) => <b>{chunk}</b>,
									organizationName: subaccount?.organizationName,
									ipsList: failedIps.join(', ')
								}}
							/>
						</span>
					),
					duration: 8000,
					showCloseButton: true,
				});
				dispatch(deletingIpsFromSubaccount(false));
			} else {
				// Add back all the ips in ipsList to checkedIPsList so that the corresponding checkboxes are checked again
				setCheckedIPsList((val) => [...new Set([...val, ...ipsList])]);
				errorHandler({ dispatch, callback: deletingIpsFromSubaccount, error, snackbar, intl });
			}
		}
	};
};

export const addingSender = (flag) => {
	return { type: ADDING_SENDERS, payload: flag };
};

export const addSender = (payloadData, snackbar, intl, id, closeModal, updateSendersList) => {
	return async (dispatch) => {
		try {
			dispatch(addingSender(true));

			await axios.post(`${URLS.IP_MANAGEMENT_API_URL}/sub-account/${id}/sender/add`, payloadData);
			updateSendersList();
			closeModal();

			dispatch(showNotificationPayload(SUCCESS, 'sender_added'));

			window.scrollTo(0, 0);
			dispatch(addingSender(false));
		} catch (error) {
			const developer_message = error?.response?.data?.error?.developer_message;
			const errorKey = developer_message === 'sender_already_exist' ? 'error_response.sender_already_exist' : '';

			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR, errorKey));
				dispatch(addingSender(false));
			});
		}
	};
};

export const fetchingIpPermissions = (flag) => {
	return {
		type: FETCHING_IP_PERMISSIONS,
		payload: flag
	};
};

export const fetchIpPermissions = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchingIpPermissions(true));
			const { data } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/account/permissions`);
			dispatch({
				type: FETCH_IP_PERMISSIONS,
				payload: data
			 });
			dispatch(fetchingIpPermissions(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(fetchingIpPermissions(false));
				dispatch(showNotificationPayload(ERROR));
			});
		}
	};
};

export const fetchingConfigIPs = (flag) => {
	return {
		type: FETCHING_CONFIG_IPS,
		payload: flag
	};
};

export const fetchConfigIPs = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchingConfigIPs(true));

			const { data } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/ip/list?limit=1000&page=1`);

			dispatch({
				type: FETCH_CONFIG_IPS,
				payload: data
			});

			dispatch(fetchingConfigIPs(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(fetchingConfigIPs(false));
			});
		}
	};
};

export const fetchingDNSConfig = (flag) => {
	return {
		type: FETCHING_DNS_CONFIG,
		payload: flag
	};
};

export const fetchDNSConfig = () => {
	return async (dispatch) => {
		try {
			dispatch({
				type: FETCH_DNS_CONFIG,
				payload: {}
			});
			dispatch(fetchingDNSConfig(true));
			const { data } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/domain/config`);
			dispatch({
				type: FETCH_DNS_CONFIG,
				payload: data
			});
			dispatch(fetchingDNSConfig(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR));
				dispatch(fetchingDNSConfig(false));
			});
		}
	};
};

export const validatingIpRecords = (flag) => {
	return {
		type: VALIDATING_IP_RECORDS,
		payload: flag
	};
};

export const validateIpRecords = ({ ipAddress, domainName, recordType }) => {
	return async (dispatch) => {
		try {
			dispatch(validatingIpRecords(true));

			const { data } = await axios.get(
				`${URLS.IP_MANAGEMENT_API_URL}/ip/${ipAddress}/validate-record/${recordType}?domain=${domainName}`
			);

			dispatch({
				type: recordType === SIMPLE ? VALIDATE_SIMPLE_IP_RECORDS : VALIDATE_ADVANCED_IP_RECORDS,
				payload: data
			});
			dispatch(validatingIpRecords(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR));
				dispatch(validatingIpRecords(false));
			});
		}
	};
};

export const associatingDomain = (flag) => {
	return {
		type: ASSOCIATING_DOMAIN,
		payload: flag
	};
};

export const associateDomain = ({ ipAddress, domainName, recordType, snackbar, intl }) => {
	return async (dispatch) => {
		try {
			dispatch(associatingDomain(true));

			const payload = {
				ip_configuration: recordType,
				domain: { name: domainName }
			};
			await axios.post(`${URLS.IP_MANAGEMENT_API_URL}/ip/${ipAddress}/associate-domain`, payload);

			snackbar({
				variant: 'default',
				type: snackbarConstants.SUCCESS,
				message: (
					<span>
						<FormattedMessage
							id="successResponse.domain_association"
							values={{
								b: (chunk) => <b>{chunk}</b>,
								ipAddress,
								domainName
							}}
						/>
					</span>
				),
				duration: 8000,
				loading: false,
				showCloseButton: true,
				handleClose: () => { },
			});

			dispatch(associatingDomain(false));
			history.push(IP_CONFIGURATION_PATH);
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR, 'errorResponse.domain_association'));
				dispatch(associatingDomain(false));
			});
		}
	};
};

export const fetchingDomains = (flag) => {
	return {
		type: FETCHING_DOMAINS,
		payload: flag
	};
};

export const fetchDomains = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchingDomains(true));

			const { data } = await axios.get(`${URLS.IP_MANAGEMENT_API_URL}/domain/list?limit=1000&page=1`);

			dispatch({
				type: FETCH_DOMAINS,
				payload: data
			});

			dispatch(fetchingDomains(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(fetchingDomains(false));
			});
		}
	};
};

export const addingDomain = (flag) => {
	return {
		type: ADDING_DOMAIN,
		payload: flag
	};
};

export const addDomain = (payload, nextStepHandler) => {
	return async (dispatch) => {
		try {
			dispatch(addingDomain(true));

			const {
				data: {
					id,
					domain_name: domainName,
					verified,
					provider,
					verifier: { method: verificationMethod } = {},
					authenticated
				}
			} = await axios.post(`${URLS.IP_MANAGEMENT_API_URL}/domain/add`, payload);
			nextStepHandler();

			// Required on the domain verification / authentication page
			setLocalStorageDomainData({
				domainName,
				domainProvider: provider,
				domainId: id,
				verificationMethod
			});

			dispatch(addingDomain(false));
		} catch (error) {
			const developer_message = error?.response?.data?.error?.developer_message;
			const errorKey = developer_message === 'domain_already_exist' ? 'errorResponse.domain_already_exist' : '';

			genericExceptionHandler(error, () => {
				dispatch(addingDomain(false));
				dispatch(showNotificationPayload(ERROR, errorKey));
			});
		}
	};
};

export const dissociatingDomain = (flag) => {
	return {
		type: DISSOCIATING_DOMAIN,
		payload: flag
	};
};

export const dissociateDomain = (ipAddress, domainName, closeModal) => {
	return async (dispatch) => {
		try {
			dispatch(dissociatingDomain(true));
			await axios.post(`${URLS.IP_MANAGEMENT_API_URL}/ip/${ipAddress}/disassociate-domain`);
			dispatch(fetchConfigIPs());
			dispatch(showNotificationPayload(SUCCESS, 'successResponse.domain_dissociated', { ipAddress, domainName }));
			dispatch(dissociatingDomain(false));
			closeModal();
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(
					ERROR,
					'errorResponse.domain_disassociation',
					{ ipAddress, domainName }
				));
				dispatch(dissociatingDomain(false));
			});
		}
	};
};

export const deletingDomain = (flag) => {
	return {
		type: DELETING_DOMAIN,
		payload: flag
	};
};

export const deleteDomain = (domainId, closeModal) => {
	return async (dispatch) => {
		try {
			dispatch(deletingDomain(true));

			await axios.delete(`${URLS.IP_MANAGEMENT_API_URL}/domain/${domainId}`);
			dispatch(fetchDomains());

			dispatch(showNotificationPayload(SUCCESS, 'successResponse.deleteDomain'));

			dispatch(deletingDomain(false));
			closeModal();
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(
					ERROR,
					'errorResponse.domain_deletion_failed'
				));
				dispatch(deletingDomain(false));
			});
		}
	};
};

export const validatingDNSRecordAction = (flag) => {
	return {
		type: VALIDATING_DNS_RECORD,
		payload: flag
	};
};

export const validateDNSRecordAction = (domainId, recordType = 'all') => {
	return async (dispatch) => {
		try {
			dispatch(validatingDNSRecordAction(true));

			// reset the validation result (if it fails to fetch, previously used results would've been used which might be incorrect)
			dispatch({ type: VALIDATE_DNS_RECORDS, payload: {} });

			const { data } = await axios.get(
				`${URLS.IP_MANAGEMENT_API_URL}/domain/${domainId}/validate-record/${recordType}`
			);

			dispatch({ type: VALIDATE_DNS_RECORDS, payload: data });

			dispatch(validatingDNSRecordAction(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR));
				dispatch(validatingDNSRecordAction(false));
			});
		}
	};
};

export const fetchingDnsForDomain = (flag) => {
	return {
		type: FETCHING_DNS_FOR_DOMAIN,
		payload: flag
	};
};

export const fetchDnsForDomain = (domainName, domainProvider = 'Other') => {
	return async (dispatch) => {
		try {
			dispatch(fetchingDnsForDomain(true));

			// reset the validation result (if it fails to fetch, previously used results will be used which might be incorrect)
			dispatch({
				type: FETCH_DNS_FOR_DOMAIN,
				payload: {}
			});

			const { data } = await axios.get(
				`${URLS.IP_MANAGEMENT_API_URL}/domain/get-dns-config/${domainProvider}?domain=${domainName}`
			);

			dispatch({
				type: FETCH_DNS_FOR_DOMAIN,
				payload: data
			});

			const { domainId } = getLocalStorageDomainData();

			dispatch(validateDNSRecordAction(domainId, 'all'));

			dispatch(fetchingDnsForDomain(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(fetchingDnsForDomain(false));
				dispatch(showNotificationPayload(ERROR));
			});
		}
	};
};

export const authenticatingDomain = (flag) => {
	return {
		type: AUTHENTICATING_DOMAIN,
		payload: flag
	};
};

export const authenticateDomain = (domainId, newDomain = true) => {
	return async (dispatch, getState) => {
		try {
			dispatch(authenticatingDomain(true));
			await dispatch(validateDNSRecordAction(domainId, 'all'));
			const {
				ipPermissions,
				domainValidationResult: {
					dns = false, dkim = false, dkim_cname1 = false, dkim_cname2 = false
				} = {} } = getState().ipManagement;

			const isDkimValid = ipPermissions.new_dkim_authentication ? (dkim_cname1 && dkim_cname2) : dkim;
			if (!dns || !isDkimValid) {
				const messageKey = `${!dns && !isDkimValid ? 'dns_dkim' : !dns ? 'dns' : 'dkim'}_record_mismatch`;
				dispatch(showNotificationPayload(ERROR, `errorResponse.${messageKey}`));
			} else {
				await axios.put(`${URLS.IP_MANAGEMENT_API_URL}/domain/${domainId}/authenticate`);

				history.push(DOMAINS_PATH);

				dispatch(showNotificationPayload(SUCCESS,
					`successResponse.domain_authenticated${newDomain ? '' : '_only'}`));
			}
			dispatch(authenticatingDomain(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				const developer_message = error?.response?.data?.error?.developer_message;
				const domainErrors = ['domain_not_found', 'domain_already_authenticated'];

				const errorMessageKey = `errorResponse.${domainErrors.includes(developer_message)
					? developer_message : `domain_authenticated${newDomain ? '' : '_only'}`}`;

				dispatch(showNotificationPayload(ERROR, errorMessageKey));
				dispatch(authenticatingDomain(false));
			});
		}
	};
};

export const togglingIpUsage = (flag) => {
	return {
		type: TOGGLING_IP_USAGE,
		payload: flag
	};
};

export const enableTransactional = (ipAddress, transactional, closeModal) => {
	return async (dispatch, getState) => {
		try {
			dispatch(togglingIpUsage(true));

			await axios.put(`${URLS.IP_MANAGEMENT_API_URL}/ip/${ipAddress}/toggle-transactional`, { transactional });

			// Modify the transactional value in the ip object of configIPsList[]
			const { configIPsList } = getState().ipManagement;
			for (let i = 0; i < configIPsList.length; i++) {
				if (configIPsList[i].ip === ipAddress) {
					configIPsList[i].transactional = transactional;
					break;
				}
			}
			dispatch({
				type: FETCH_CONFIG_IPS,
				payload: { ips: [...configIPsList] } // Creating a new array since this needs to trigger the useEffect in ListIps
			});

			dispatch(showNotificationPayload(SUCCESS,
				`successResponse.${transactional ? 'enable_transactional' : 'disable_transactional'}`,
				{ ipAddress }));
			closeModal();
			dispatch(togglingIpUsage(false));
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR,
					`errorResponse.${transactional ? 'enable_transactional' : 'disable_transactional'}`,
					{ ipAddress }));
				dispatch(togglingIpUsage(false));
			});
		}
	};
};

export const changingDomainSignature = (flag) => {
	return {
		type: CHANGING_DOMAIN_SIGNATURE,
		payload: flag
	};
};

export const changeDomainSignature = (ipAddress, domainId, callbackOnSuccess) => {
	return async (dispatch) => {
		try {
			dispatch(changingDomainSignature(true));
			await axios.put(`${URLS.IP_MANAGEMENT_API_URL}/ip/${ipAddress}/change-dkim/${domainId}`);
			dispatch(changingDomainSignature(false));
			dispatch(showNotificationPayload(SUCCESS, 'successResponse.change_domain_signature'));
			callbackOnSuccess();
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR, 'errorResponse.change_domain_signature'));
				dispatch(changingDomainSignature(false));
			});
		}
	};
};
