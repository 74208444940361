import { create } from 'axios';
import history from '@dtsl/common-billing-components/src/Utils/history';
import reduxStore from '../store';
import { ERROR, SESSION_EXPIRED } from '../constants/notification';
import { showNotificationPayload } from '../utils/helper';
import URLS from '../utils/urlHelper';

export const config = {
	baseURL: URLS.ACCOUNT_APP_URL,
	withCredentials: true,
	timeout: 30000
};
const axiosInstance = create(config);

export const setupInterceptors = () => {
	axiosInstance.interceptors.response.use(
		(response) => response,

		(error) => {
			const {
				response: {
					status,
					data: { redirect_url }
				} = {}
			} = error;

			if (status === 403) {
				if (redirect_url) window.location.replace(redirect_url);
				else history.push('/pageAccessDenied');
			}

			if (status === 401) {
				reduxStore.dispatch(showNotificationPayload(ERROR, `errorResponse.${SESSION_EXPIRED}`));
				// window.location.replace(`${ACCOUNT_APP_URL}/account/login`);
			}

			return Promise.reject(error);
		}
	);
};

export const genericExceptionHandler = (error, callback) => {
	const genericStatusCodes = [401, 403];
	const { response: { status } = {} } = error;

	if (!genericStatusCodes.includes(status)) {
		callback();
	}
};

export default axiosInstance;
