import React from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PrimaryLinks from './PrimaryLinks';
import HeaderIcons from '../SVG/HeaderIcons';
import icons from '../../constants/headerIcons';
import HelpDropdown from './HelpDropdown';
import NavigationDropdown from './NavigationDropdown';

const {
	ORGANIZATION_LOGO,
} = icons;

class Header extends React.Component {
	componentDidMount() {
		this.props.fetchCountries();
	}

	render() {
		const {
			corpAccountInfo: {
				email = '',
				fetchingCorpInfo: isFetching,
				organizationName,
				analytics,
				hasApiGranted,
				canManageOrganizationMembers,
				isOwner,
				hasSecurity
			},
			intl: { locale },
		} = this.props;

		return (
			<nav className="corporate__header brevo__background">

				<Link
					to="/"
					className="header__organization corporate__logo brevo__background"
					rel="home"
				>
					<HeaderIcons iconType={ORGANIZATION_LOGO} />
				</Link>

				<PrimaryLinks
					analytics={analytics}
				/>

				<div className="header_dropdowns">
					<HelpDropdown locale={locale} />
					<NavigationDropdown
						isFetching={isFetching}
						email={email}
						organizationName={organizationName}
						hasApiGranted={hasApiGranted}
						canManageOrganizationMembers={canManageOrganizationMembers}
						isOwner={isOwner}
						hasSecurity={hasSecurity}
					/>
				</div>
			</nav>
		);
	}
}

Header.propTypes = {
	intl: PropTypes.object.isRequired,
	corpAccountInfo: PropTypes.object.isRequired,
	fetchCountries: PropTypes.func.isRequired
};

export default injectIntl(Header);
