import { FETCH_COUNTRIES, FETCH_TIMEZONES } from './actionTypes';
import axios, { genericExceptionHandler } from './axios';
import { ERROR } from '../constants/notification';
import { getUniqueCountries, showNotificationPayload } from '../utils/helper';
import URLS from '../utils/urlHelper';

export const fetchCountries = () => {
	return async (dispatch) => {
		try {
			const { data } = await axios(
				`https://static.${APP_DOMAIN}.com/js/countries-${APP_ENV === 'production' ? 'prod' : 'staging'}.json`,
				{ withCredentials: false }
			);
		    dispatch({
				type: FETCH_COUNTRIES,
				payload: { error: false, data: getUniqueCountries(data) }
			});
		} catch (error) {
			dispatch({
				type: FETCH_COUNTRIES,
				payload: { error: true, data: {} }
			});
		}
	};
};

export const fetchTimeZones = () => {
	return async (dispatch, getState) => {
		try {
			const isTimeZoneListAvailable = getState().utilData.timeZones.data.length;
			if (isTimeZoneListAvailable) return;
			const { data: { timezones = [] } } = await axios(`${URLS.ACCOUNT_MANAGEMENT_API_URL}
				/settings/list-timezone`);
		    dispatch({
				type: FETCH_TIMEZONES,
				payload: { error: false, data: timezones }
			});
		} catch (error) {
			genericExceptionHandler(error, () => {
				dispatch(showNotificationPayload(ERROR));

				dispatch({
					type: FETCH_TIMEZONES,
					payload: { error: true, data: [] }
				});
			});
		}
	};
};
