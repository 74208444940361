// Accounts
export const ADD_ACCOUNT = 'addAccount';
export const ADDING_ACCOUNT = 'addingAccount';
export const ALLOCATING_CONSUMABLES = 'allocatingConsumables';
export const UPDATING_CONSUMABLES = 'updatingConsumables';
export const FETCH_ACCOUNT_LIST = 'fetchAccountList';
export const FETCHING_ACCOUNT_LIST = 'fetchingAccountList';
export const FETCH_ALL_SUB_ACCOUNTS = 'fetchAllSubAccounts';
export const FETCHING_ALL_SUB_ACCOUNTS = 'fetchingAllSubAccounts';
export const FETCH_CORP_ACCOUNT_INFO = 'fetchCorpAccountInfo';
export const FETCHING_CORP_INFO = 'fetchingCorpInfo';
export const UPDATE_CORP_INFO = 'updateCorpInfo';
export const LOGGING_IN_SUBACCOUNT = 'loggingInSubaccount';
export const RESET_SUB_ACCOUNT_LIST = 'resetAubAccountList';
export const ADD_FILTERED_SUBACCOUNT_IDS = 'addFilteredSubaccountIds';
export const SET_CURRENT_SUBACCOUNT_IDS = 'setCurrentSubaccountIds';
export const FETCHING_SUBORGANIZATION_SETTINGS = 'fetchingSubOrganizationSettings';
export const FETCH_SUBORGANIZATION_SETTINGS = 'fetchSubOrganizationSettings';
export const UPDATING_SUBORGANIZATION_SETTINGS = 'updatingSubOrganizationSettings';
export const FETCHING_GROUPS_FOR_SUBACCOUNT = 'fetchingGroupsForSubaccount';
export const FETCH_GROUPS_FOR_SUBACCOUNT = 'fetchGroupsForSubaccount';
export const FETCHING_GROUPS_BADGES = 'fetchingGroupsBadges';
export const FETCH_GROUPS_BADGES = 'fetchGroupsBadges';

// IP Management
export const FETCHING_IPS = 'fetchingIps';
export const FETCH_IPS = 'fetchIps';
export const SET_TOTAL_IPS_COUNT = 'setTotalIpsCount';
export const ASSIGNING_SUBACCOUNTS_TO_IP = 'assigningSubaccountsToIp';
export const ASSIGN_SUBACCOUNTS_TO_IP = 'assignSubaccountsToIp';
export const DISASSOCIATING_IP = 'disassociatingIp';
export const UPDATE_IP = 'updateIp';
export const FETCHING_ASSIGNED_IPS = 'fetchingAssignedIps';
export const FETCH_ASSIGNED_IPS = 'fetchAssignedIps';
export const ASSIGNING_IPS_TO_SUBACCOUNT = 'assigningIpsToSubaccount';
export const UPDATE_ASSIGNED_IPS = 'updateAssignedIps';
export const DELETING_IPS_FROM_SUBACCOUNT = 'deletingIpsFromSubaccount';
export const ADDING_SENDERS = 'addingSenders';
export const FETCHING_SUBACCOUNTS_FOR_IP = 'fetchingSubaccountsForIp';
export const FETCH_SUBACCOUNTS_FOR_IP = 'fetchSubaccountsForIp';
export const REMOVE_SUBACCOUNTS_LIST_FROM_IPS = 'removeSubaccountsFromIps';
export const FETCHING_CONFIG_IPS = 'fetchingConfigIPs';
export const FETCH_IP_PERMISSIONS = 'fetchIpPermissions';
export const FETCHING_IP_PERMISSIONS = 'fetchingIpPermissions';
export const FETCH_CONFIG_IPS = 'fetchConfigIPs';
export const FETCHING_DOMAINS = 'fetchingDomains';
export const FETCH_DOMAINS = 'fetchDomains';
export const FETCHING_DNS_CONFIG = 'fetchingDNSConfig';
export const FETCH_DNS_CONFIG = 'fetchDNSConfig';
export const VALIDATING_IP_RECORDS = 'validatingIpRecords';
export const VALIDATE_SIMPLE_IP_RECORDS = 'validateSimpleIpRecords';
export const VALIDATE_ADVANCED_IP_RECORDS = 'validateAdvancedIpRecords';
export const ASSOCIATING_DOMAIN = 'associatingDomain';
export const ASSOCIATE_DOMAIN = 'associateDomain';
export const ADDING_DOMAIN = 'addingDomain';
export const ADD_DOMAIN = 'addDomain';
export const AUTHENTICATING_DOMAIN = 'authenticatingDomain';
export const FETCHING_DNS_FOR_DOMAIN = 'fetchingDnsForDomain';
export const FETCH_DNS_FOR_DOMAIN = 'fetchDnsForDomain';
export const VALIDATING_DNS_RECORD = 'validatingDnsRecord';
export const VALIDATE_DNS_RECORDS = 'validateDNSRecords';
export const DISSOCIATING_DOMAIN = 'dissociatingDomain';
export const DELETING_DOMAIN = 'deletingDomain';
export const TOGGLING_IP_USAGE = 'togglingIpUsage';
export const CHANGING_DOMAIN_SIGNATURE = 'changingDomainSignature';

// App management
export const FETCHING_ALL_APPS = 'fetchingAllApps';
export const FETCH_ALL_APPS = 'fetchAllApps';
export const SET_ACTIVATED_APPS = 'setActivatedApps';
export const TOGGLING_APP = 'togglingApp';
export const MODIFY_ACTIVATED_APPSLIST = 'modifyActivatedAppsList';
export const MODIFY_ACTIVATED_APPSLIST_V2 = 'modifyActivatedAppsList_v2';

// Master userManagement
export const FETCH_INVITED_USERS = 'fetchInvitedUsers';
export const FETCHING_INVITED_USERS = 'fetchingInvitedUsers';
export const FETCH_INVITED_USERS_PERMISSIONS = 'fetchInvitedUsersPermissions';
export const FETCHING_INVITED_USERS_PERMISSIONS = 'fetchingInvitedUsersPermissions';
export const ADDING_NEW_INVITED_USER = 'addingNewInvitedUser';
export const ALL_RESOURCES_ACCESS = 'allResourcesAccess';
export const FETCH_EDIT_INVITED_USERS = 'fetchEditInvitedUsers';
export const FETCHING_EDIT_INVITED_USERS = 'fetchingEditInvitedUsers';
export const EDITING_INVITED_USERS = 'editingInvitedUsers';
export const FETCH_RESOURCES_LIST = 'fetchResourcesList';

// Subaccount userManagement
export const FETCH_SUBACC_INVITED_USERS = 'fetchSubaccInvitedUsers';
export const FETCHING_SUBACC_INVITED_USERS = 'fetchingSubaccInvitedUsers';
export const FETCH_SUBORGANIZATIONS_USERS_EMAILS = 'fetchSubOrganizationsUsersEmails';
export const FETCHING_SUBORGANIZATIONS_USERS_EMAILS = 'fetchingSubOrganizationsUsersEmails';
export const ASSOCIATE_SUBACCOUNTS = 'associateSubaccounts';
export const ASSOCIATING_SUBACCOUNTS = 'associatingSubaccounts';
export const SENDING_INVITATION = 'sendingInvitation';
export const EDITING_PERMISSIONS = 'editingPermissions';
export const INVITING_AGAIN = 'invitingAgain';
export const INVITE_AGAIN = 'inviteAgain';
export const DELETING_INVITATION = 'deletingInvitation';
export const DELETE_INVITATION = 'deleteInvitation';
export const REVOKING_ACCESS = 'revokingAccess';
export const CANCELING_INVITATION = 'cancelingInvitation';
export const CANCEL_INVITATION = 'cancelInvitation';
export const UPDATE_INVITED_USERS = 'updateInvitedUsers';
export const CUSTOM_SELECTED_PERMISSIONS = 'customSelectedPermissions';
export const MULTIPLE_TOGGLE_STATE = 'multipleToggleState';
export const MODIFY_CONNECTED_USERS = 'modifyConnectedUsers';
export const MODIFY_NON_CONNECTED_USERS = 'modifyNonconnectedUsers';
export const VALIDATING_INVITEE = 'validatingInvitee';
export const VALIDATE_INVITEE = 'validateInvitee';
export const MODIFY_INVITED_USERS = 'modifyInvitedUsers';

// Plan
export const FETCH_NEW_ACCOUNT_PLAN = 'fetchNewAccountPlan';
export const FETCHING_NEW_ACCOUNT_PLAN = 'fetchingNewAccountPlan';
export const FETCH_FEATURE_USES = 'fetchFeatureUses';
export const PLAN_LOAD_ERROR = 'planLoadError';
export const FETCHING_FEATURE_USES = 'fetchingFeatureuses';
export const FETCH_CORP_PLAN = 'fetchCorpPlan';
export const FETCHING_PLAN = 'fetchingPlan';
export const RETRYING_PLAN = 'retryingPlan';
export const FETCH_CAN_RETRY = 'fetchCanRetry';

// Plan details ent_v2
export const FETCHING_FEATURE_USES_ENTV2 = 'fetchingFeatureusesEntv2';
export const FETCH_FEATURE_USES_ENTV2 = 'fetchFeatureUsesEntv2';
export const FETCHING_MISSING_IDS_PLAN_DATA = 'fetchingMissingIdsPlanData';
export const FETCH_MISSING_IDS_PLAN_DATA = 'fetchMissingIdsPlanData';
export const RETRY_COUNT_ENTV2 = 'retryCountEntv2';
export const FETCHING_MASTER_PLAN_ENTV2 = 'fetchingMasterPlanEntv2';
export const FETCH_MASTER_PLAN_ENTV2 = 'fetchMasterPlanEntv2';
export const FETCHING_ADDONS_DETAILS = 'fetchingAddonsDetails';
export const FETCH_ADDONS_DETAILS = 'fetchAddonsDetails';

// Customize plan
export const UPDATE_SUBACCOUNT_PLAN = 'updateSubaccountPlan';

// Profile
export const UPDATING_PREFERENCES = 'updatingPreferences';
export const FETCH_PROFILE_INFO = 'fetchProfileInfo';
export const UPDATE_PASSWORD = 'updatePassword';
export const UPDATE_PROFILE_INFO = 'updateProfileInfo';
export const IS_SMS_VALIDATION_SENT = 'isSmsValidationSent';
export const IS_EMAIL_VALIDATION_SENT = 'isEmailValidationSent';
export const SEND_OTP = 'sendOtp';
export const FETCH_PHONE_CHANGE_INFO = 'fetchPhoneChangeInfo';
export const SUBMIT_OTP = 'submitOtp';

// Util data
export const FETCH_COUNTRIES = 'fetchCountries';
export const FETCH_TIMEZONES = 'fetchTimeZones';

// Pagination
export const CHANGE_PAGE_LIMIT = 'changePageLimit';
export const CHANGE_PAGE = 'changePage';
export const RESET_PAGINATION = 'resetPagination';

// Saml
export const FETCHING_SAML_IDENTIFIER = 'fetchingSamlIdentifier';
export const FETCH_SAML_IDENTIFIER = 'fetchSamlIdentifier';
export const VERIFYING_SAML_IDENTIFIER = 'verifyingSamlIdentifier';
export const SAVING_SETTINGS = 'savingSettings';
export const UPDATING_SAML_AUTHENTICATION = 'updatingSamlAuthentication';
export const FETCHING_SAML_METADATA = 'fetchingSamlMetaData';
export const FETCH_SAML_METADATA = 'fetchSamlMetaData';

// Analytics
export const FETCH_ANALYTICS_DASHBOARDS = 'fetchAnalyticsDashboard';
export const FETCHING_ANALYTICS_DASHBOARDS = 'fetchingAnalyticsDashboard';
export const FETCH_ANALYTICS_DASHBOARD_URL = 'fetchAnalyticsDashboardUrl';
export const RESET_ANALYTICS_DASHBOARD_URL = 'resetAnalyticsDashboardUrl';

// Notification constants
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const INFO = 'INFO';

// Action typesOfMethods
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

// Filter AccountList
export const SELECTED_SUBACCOUNTS = 'selectedSubaccounts';

// Manage Subaccount
export const SUBACCOUNT_NAME = 'subaccountName';

// Organizations List
export const FETCHING_ORGANIZATIONS_LIST = 'fetchingOrganizationsList';
export const FETCH_ORGANIZATIONS_LIST = 'fetchOrganizationsList';

// Group Management
export const FETCHING_GROUPS = 'fetchingGroups';
export const FETCH_GROUPS = 'fetchGroups';
export const CREATING_GROUP = 'creatingGroup';
export const DELETING_GROUP = 'deletingGroup';
export const EDITING_GROUP = 'editingGroup';
export const FETCHING_EDIT_GROUP_DATA = 'fetchingEditGroupData';
export const FETCH_EDIT_GROUP_DATA = 'fetchEditGroupData';

// Banner Notification
export const FETCH_NOTIFICATION = 'fetchNotification';
export const REMOVE_NOTIFICATION = 'removeNotication';
