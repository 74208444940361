import {
	ASSIGNING_SUBACCOUNTS_TO_IP, DISASSOCIATING_IP,
	FETCHING_IPS, FETCH_IPS, UPDATE_IP, SET_TOTAL_IPS_COUNT, FETCHING_ASSIGNED_IPS, FETCH_ASSIGNED_IPS,
	ASSIGNING_IPS_TO_SUBACCOUNT, UPDATE_ASSIGNED_IPS, DELETING_IPS_FROM_SUBACCOUNT, ADDING_SENDERS,
	FETCHING_SUBACCOUNTS_FOR_IP, FETCH_SUBACCOUNTS_FOR_IP, REMOVE_SUBACCOUNTS_LIST_FROM_IPS,
	FETCH_CONFIG_IPS, FETCHING_CONFIG_IPS, FETCHING_DNS_CONFIG, FETCH_DNS_CONFIG,
	VALIDATING_IP_RECORDS, VALIDATE_SIMPLE_IP_RECORDS, VALIDATE_ADVANCED_IP_RECORDS, ASSOCIATING_DOMAIN,
	FETCHING_DOMAINS, FETCH_DOMAINS, ADDING_DOMAIN, DISSOCIATING_DOMAIN, DELETING_DOMAIN,
	FETCHING_DNS_FOR_DOMAIN, FETCH_DNS_FOR_DOMAIN, VALIDATE_DNS_RECORDS, TOGGLING_IP_USAGE,
	FETCHING_IP_PERMISSIONS, FETCH_IP_PERMISSIONS, AUTHENTICATING_DOMAIN, VALIDATING_DNS_RECORD,
	CHANGING_DOMAIN_SIGNATURE,

} from '../actions/actionTypes';
import {
	getUpdatedAssignedIps, getUpdatededIpsList, deleteSubaccountsKeys
} from '../components/IpManagement/utils/helper';

const initialState = {
	totalIpsCount: null,
	fetchingIps: false,
	fetchingSubaccountsForIp: false,
	ipsList: [],
	ipsCollection: null,
	assigningSubaccountsToIp: false,
	disassociatingIp: false,
	updatedIpsList: null,
	fetchingAssignedIps: null,
	assignedIps: {},
	assigningIpsToSubaccount: false,
	deletingIpsFromSubaccount: false,
	addingSender: false,
	fetchingIpPermissions: false,
	ipPermissions: {},
	fetchingConfigIPs: false,
	configIPsList: [],
	fetchingDomainList: false,
	domainsList: [],
	fetchingDNSConfig: false,
	dnsConfig: {},
	validatingIpRecords: false,
	validationResultSimple: {},
	validationResultAdvanced: {},
	associatingDomain: false,
	dissociatingDomain: false,
	togglingIpUsage: false,
	addingDomain: false,
	deletingDomain: false,
	fetchingDnsToAddDomain: false,
	dnsToAddDomain: {},
	domainValidationResult: {},
	validatingDnsDomain: false,
	authenticatingDomain: false,
	changingDomainSignature: false,
};

const ipManagement = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_IPS:
			return { ...state, fetchingIps: action.payload };

		case FETCH_IPS:
			return {
				...state,
				ipsList: Object.values(action.payload),
				updatedIpsList: Object.values(action.payload),
				ipsCollection: action.payload
			};

		case SET_TOTAL_IPS_COUNT:
			return { ...state, totalIpsCount: action.payload };

		case FETCHING_SUBACCOUNTS_FOR_IP:
			return { ...state, fetchingSubaccountsForIp: action.payload };

		case FETCH_SUBACCOUNTS_FOR_IP: {
			const { ipAddress, sub_accounts } = action.payload;
			const tempCollection = { ...state.ipsCollection };
			tempCollection[ipAddress].sub_accounts = sub_accounts;
			return {
				...state,
				ipsCollection: tempCollection,
				ipsList: Object.values(tempCollection),
			};
		}

		case REMOVE_SUBACCOUNTS_LIST_FROM_IPS:
			 const tempCollection = deleteSubaccountsKeys(state);
			return {
				...state,
				ipsCollection: tempCollection,
				ipsList: Object.values(tempCollection),
				updatedIpsList: Object.values(tempCollection)
			};

		case ASSIGNING_SUBACCOUNTS_TO_IP:
			return { ...state, assigningSubaccountsToIp: action.payload };

		case DISASSOCIATING_IP:
			return { ...state, disassociatingIp: action.payload };

		case UPDATE_IP:
			const { ipAddress, data, operation } = action.payload;
			return { ...state, updatedIpsList: getUpdatededIpsList(state, ipAddress, data, operation) };

		case FETCHING_ASSIGNED_IPS:
			return { ...state, fetchingAssignedIps: action.payload };

		case FETCH_ASSIGNED_IPS:
			return { ...state, assignedIps: action.payload };
		case ASSIGNING_IPS_TO_SUBACCOUNT:
			return { ...state, assigningIpsToSubaccount: action.payload };

		case DELETING_IPS_FROM_SUBACCOUNT:
			return { ...state, deletingIpsFromSubaccount: action.payload };

		case UPDATE_ASSIGNED_IPS:
			return { ...state, assignedIps: getUpdatedAssignedIps(state, action.payload) };

		case ADDING_SENDERS:
			return { ...state, addingSender: action.payload };

		case FETCHING_IP_PERMISSIONS:
			return { ...state, fetchingIpPermissions: action.payload };
		case FETCH_IP_PERMISSIONS:
			return { ...state, ipPermissions: action.payload };
		case FETCHING_CONFIG_IPS:
			return { ...state, fetchingConfigIPs: action.payload };
		case FETCH_CONFIG_IPS:
			return { ...state, configIPsList: action.payload?.ips };

		case FETCHING_DOMAINS:
			return { ...state, fetchingDomainList: action.payload };
		case FETCH_DOMAINS:
			return { ...state, domainsList: action.payload?.domains };
		case FETCHING_DNS_CONFIG:
			return { ...state, fetchingDNSConfig: action.payload };
		case FETCH_DNS_CONFIG:
			return { ...state, dnsConfig: action.payload };

		case VALIDATING_IP_RECORDS:
			return { ...state, validatingIpRecords: action.payload };
		case VALIDATE_SIMPLE_IP_RECORDS:
			return { ...state, validationResultSimple: action.payload };

		case VALIDATE_ADVANCED_IP_RECORDS:
			return { ...state, validationResultAdvanced: action.payload };

		case ASSOCIATING_DOMAIN:
			return { ...state, associatingDomain: action.payload };
		case DISSOCIATING_DOMAIN:
			return { ...state, dissociatingDomain: action.payload };

		case ADDING_DOMAIN:
			return { ...state, addingDomain: action.payload };

		case AUTHENTICATING_DOMAIN:
			return { ...state, authenticatingDomain: action.payload };

		case DELETING_DOMAIN:
			return { ...state, deletingDomain: action.payload };

		case FETCHING_DNS_FOR_DOMAIN:
			return { ...state, fetchingDnsToAddDomain: action.payload };

		case FETCH_DNS_FOR_DOMAIN:
			return { ...state, dnsToAddDomain: action.payload };

		case VALIDATE_DNS_RECORDS:
			return { ...state, domainValidationResult: action.payload };

		case VALIDATING_DNS_RECORD:
			return { ...state, validatingDnsDomain: action.payload };

		case TOGGLING_IP_USAGE:
			return { ...state, togglingIpUsage: action.payload };

		case CHANGING_DOMAIN_SIGNATURE:
			return { ...state, changingDomainSignature: action.payload };

		default:
			return state;
	}
};

export default ipManagement;
