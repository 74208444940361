import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { LoaderSvg } from '../SVG/SvgIcon';

function Loader({ loaderSize, style, text }) {
	let loaderSizeClass;
	const { fill, ...restStyles } = style;
	const loaderText = !!text && <FormattedMessage id={text} />;

	if (loaderSize === 'small') {
		loaderSizeClass = 'progress-indicator_small';
	}

	return (
		<div className={`progress-indicator ${loaderSizeClass}`} style={{ ...restStyles }} data-testid="loader">
			<LoaderSvg fill={fill} />{loaderText}
		</div>
	);
}

Loader.propTypes = {
	loaderSize: PropTypes.string,
	style: PropTypes.object,
	text: PropTypes.string
};

Loader.defaultProps = {
	loaderSize: '',
	style: {},
	text: ''
};

export default Loader;
