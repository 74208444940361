import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Tabs } from '@dtsl/react';

const getActiveTabId = ({ pathname = '/' }) => {
	switch (pathname) {
		case '/':
			return 0;
		case '/analytics':
			return 1;
		default:
			return 0;
	}
};

function PrimaryLinks({ analytics }) {
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();

	// 0 for / and 1 for /analytics
	const [activeTabId, setActiveTabId] = useState(getActiveTabId(location));

	const primaryNavLinks = ['accounts', 'analytics'];

	useEffect(() => {
		setActiveTabId(getActiveTabId(location));
	}, [location?.pathname]);

	const onSelect = (tab) => {
		setActiveTabId(tab?.id);
		history.push(tab?.id === 0 ? '/' : '/analytics');
	};
	const tabs = primaryNavLinks.map((link, i) => {
		return {
			id: i,
			label: intl.formatMessage({ id: `header.${link}` }),
			href: '#',
			target: '_self',
		};
	});

	// Below links are not in First release plan, will be added in future
	// { link: 'domains', to: `${BASE_URL}/domains`, contentId: 'header.domains', isActive: activeLink === 'domains' },
	// { link: 'stats', to: `${BASE_URL}/stats`, contentId: 'header.stats', isActive: activeLink === 'stats' }

	return (
		<div className="header__tabs">
			<Tabs
				activeIndex={activeTabId}
				onSelect={onSelect}
				tabs={tabs}
			/>
		</div>
	);
}

PrimaryLinks.propTypes = {
	analytics: PropTypes.string.isRequired,
};
export default PrimaryLinks;
