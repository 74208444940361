import { UPDATE_NOTIFICATION } from '../actions/actionTypes';

const initialState = {
	showNotification: false,
	type: '',
	formattedMessageId: '',
	messageParams: {},
};

const notification = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_NOTIFICATION:
			return {
				...action.payload
			};
		default:
			return state;
	}
};

export default notification;
