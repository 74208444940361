import { FETCH_GROUPS, FETCHING_GROUPS, CREATING_GROUP, DELETING_GROUP,
	 EDITING_GROUP, FETCHING_EDIT_GROUP_DATA, FETCH_EDIT_GROUP_DATA } from '../actions/actionTypes';

const initialState = {
	fetchingGroupsList: false,
	groupsList: [],
	addingGroup: false,
	deletingGroup: false,
	editingGroup: false,
	fetchingEditGroupData: false,
	editGroupData: {}

};

const groupManagement = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_GROUPS:
			return { ...state, fetchingGroupsList: action.payload };
		case FETCH_GROUPS:
			return { ...state, groupsList: action.payload };
		case CREATING_GROUP:
			return { ...state, addingGroup: action.payload };
		case DELETING_GROUP:
			return { ...state, deletingGroup: action.payload };
		case EDITING_GROUP:
			return { ...state, editingGroup: action.payload };

		case FETCHING_EDIT_GROUP_DATA:
			return { ...state, fetchingEditGroupData: action.payload };

		case FETCH_EDIT_GROUP_DATA:
			return { ...state, editGroupData: action.payload };

		default:
			return state;
	}
};

export default groupManagement;
