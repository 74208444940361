import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Navigation } from '@dtsl/navigation';

const getActiveIndices = (pathName) => {
	// multiple ip pages
	if (pathName.includes('/ip/')) return ['ip_management'];

	const urlToIdMap = {
		'/': 'home',
		'/analytics': 'analytics',
		'/organization/invited/users': 'users',
		'/sub-accounts/invited/users': 'users',
		'/manage/api': 'api',
		'/saml/configure': 'security',
		'/billing/my-plan': 'my_plan',
		'/billing/payment-methods': 'my_plan',
		'/billing/billing-information': 'my_plan',
		'/billing/invoices': 'my_plan',
		'/billing/credit-notes': 'my_plan'
	};
	return [urlToIdMap[pathName]];
};

const NavigationImpl = ({ navData }) => {
	const itemKeys = Object.keys(navData);
	const [navItems, setNavItems] = useState({});
	const { fetchingCorpInfo } = useSelector((state) => state?.corpAccountInfo);

	const history = useHistory();
	const location = useLocation();
	const [activeIndices, setActiveIndices] = useState(getActiveIndices(location.pathname)); // Need to be set based on the url

  const handleClick = (event, item = {}) => {
    event.preventDefault();

    // If an item is locked, don't redirect
    if (item.urlsMeta[0].meta_data_details?.isLocked) return;

    setActiveIndices([item.id]);
    history.push(item.urlsMeta[0].url);
  };

  useEffect(()=> {
	location.pathname.includes('/profile') && setActiveIndices([]);
  }, [location.pathname]);

	useEffect(() => {
		const tempNavItems = itemKeys.reduce((acc, itemKey) => {
			acc[itemKey] = { ...navData[itemKey], onClick: handleClick };
			return acc;
		}, {});
		setNavItems(tempNavItems);
		setActiveIndices(getActiveIndices(location.pathname)); // To set the active stroke
	}, [navData, location.pathname]);

	return (
		<Navigation
			theme="enterprise"
			navData={navItems}
			activeIndices={activeIndices}
			loading={fetchingCorpInfo}
			nonMenuIds={['brevo_logo']}
		/>
	);
};

NavigationImpl.propTypes = {
	navData: PropTypes.object
};

export default NavigationImpl;
