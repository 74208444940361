import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalHeader, GlobalHeaderItems } from '@dtsl/global-header';
import { OptionTypes, Tag, useSnackbar } from '@dtsl/react';
import {
	BookOpen,
	Company,
	HelpCircle,
	LifeBuoy,
	LogOut,
} from '@dtsl/icons';
import { capitalizeFirstChar } from '../../utils/helper';
import { fetchOrganizationList } from '../../actions/organizationList';
import URLS from '../../utils/urlHelper';

function GlobalHeaderImpl(props) {
	const {
		hideSidebar
	} = props;

	const history = useHistory();
	const location = useLocation();
	const intl = useIntl();
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const { messages, locale } = intl;

	const { email, familyName, givenName,
		 fetchingCorpInfo } = useSelector((state) => state.corpAccountInfo);

	const { organizationsList, fetchingOrganizationsList } = useSelector((state) => state.organizationsList);

	useEffect(() => {
		!organizationsList.length && dispatch(fetchOrganizationList(snackbar, intl));
	}, []);

	const shouldShowAvatar = () => {
		return !!(familyName || givenName);
	};

	// If given_name and family_name aren't present in the DB, don't show the Avatar. Instead show "My profile"
	const getAvatarLabel = () => {
		if (shouldShowAvatar()) {
			const firstInitial = givenName[0] || '';
			const secondInitial = familyName[0] || '';
			return firstInitial + secondInitial;
		}
		return messages['profile.heading'];
	};

	// List of all breadcrumbs, using which the combinations are done
	// Ex: Using "Home" & "Add suborganization", "Home/Add suborganiztion" breadcrumb will be created in getBreadCrumbsListForUrl() for the add suborgnization page
	const breadCrumbsList = [
		{ label: intl.formatMessage({ id: 'sidebar.home' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'addAccount.header_v2' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'sidebar.analytics' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'sidebar.users' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'subaccountUsers.invite_new_user.addUser' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'subaccountUsers.listing.action' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'sidebar.ips_management' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'sidebar.api' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'sidebar.security' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'sidebar.my_plan' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'header.organization.profile' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'subaccountlisting_v2.manage_subOrganizations' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'ipManagement.configuration.associate_domain.breadcrumb' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'ipManagement.configuration.configure_domain.breadcrumb' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'addDomain.addDomain' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'ipManagement.configuration.configure_domain.edit.breadcrumb' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'viewDomainConfiguration.configHeader' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'ipManagement.configuration.change_domain_signature.change_domain_signature' }), url: '#', disabled: false, target: '_self' },
		{ label: intl.formatMessage({ id: 'addDomain.authenticateDomain' }), url: '#', disabled: false, target: '_self' },
	];

	// For a given url, return the list of breadcumbs, in correct order.
	// Ex: For Add sub-organization, return [Home, Add suborganization]
	const getBreadCrumbsListForUrl = ({ pathname }) => {
		// Edit permissions of an admin user
		if (pathname.includes('/organization/member/role')) {
			return [breadCrumbsList[3], breadCrumbsList[5]];
		}
		switch (pathname) {
			case '/':
				return [breadCrumbsList[0]];
			case '/accounts/add':
				return [breadCrumbsList[0], breadCrumbsList[1]];
			case '/analytics':
				return [breadCrumbsList[2]];
			// Users listing
			case '/organization/invited/users':
			case '/sub-accounts/invited/users':
				return [breadCrumbsList[3]];
			// Invite new admin user
			case '/organization/invitation/send':
				return [breadCrumbsList[3], breadCrumbsList[4]];
			// Invite new sub-organization user
			case '/add-user':
				return [breadCrumbsList[3], breadCrumbsList[4]];
			// Edit permissions of a sub-organizatios nuser
			case '/user/connect-sub-account':
				return [breadCrumbsList[3], breadCrumbsList[5]];
			case '/manage/api':
				return [breadCrumbsList[7]];
			case '/saml/configure':
				return [breadCrumbsList[8]];
			// My plan
			case '/billing/my-plan':
			case '/billing/payment-methods':
			case '/billing/billing-information':
			case '/billing/invoices':
				return [breadCrumbsList[9]];
			// My Profile
			case '/profile/information':
			case '/profile/password':
			case '/profile/preferences':
				return [breadCrumbsList[10]];
			case '/sub-account/consumption-limitations':
			case '/sub-account/apps':
			case '/sub-account/general-settings':
				return [breadCrumbsList[0], breadCrumbsList[11]];
			case '/ip/assignment':
			case '/ip/configuration':
			case '/ip/domains':
				return [breadCrumbsList[6]];
			case '/ip/configuration/associate':
				return [breadCrumbsList[6], breadCrumbsList[12]];
			case '/ip/configuration/configure':
				return [breadCrumbsList[6], breadCrumbsList[13]];
			case '/ip/configuration/edit':
				return [breadCrumbsList[6], breadCrumbsList[15]];
			case '/add-domain':
				return [breadCrumbsList[6], breadCrumbsList[14]];
			case '/ip/domains/config':
				return [breadCrumbsList[6], breadCrumbsList[16]];
			case '/ip/configuration/domain-signature':
				return [breadCrumbsList[6], breadCrumbsList[17]];
			case '/ip/domains/authenticate':
				return [breadCrumbsList[6], breadCrumbsList[18]];
			case '/add-group':
				return [breadCrumbsList[3], breadCrumbsList[4]];
		}
	};

	// When a breadcrumb item is clicked, redirect to a path, based on the clicked label
	const urlMapping = {
		[intl.formatMessage({ id: 'sidebar.home' })]: '/',
		[intl.formatMessage({ id: 'addAccount.header_v2' })]: '/accounts/add',
		[intl.formatMessage({ id: 'sidebar.analytics' })]: '/analytics',
		[intl.formatMessage({ id: 'sidebar.users' })]: location.pathname.includes('organization') ? '/organization/invited/users' : '/sub-accounts/invited/users',
		[intl.formatMessage({ id: 'sidebar.ips_management' })]: '/ip/domains',
		[intl.formatMessage({ id: 'sidebar.api' })]: '/manage/api',
		[intl.formatMessage({ id: 'sidebar.security' })]: '/saml/configure',
		[intl.formatMessage({ id: 'sidebar.my_plan' })]: '/billing/my-plan',
		[intl.formatMessage({ id: 'header.organization.profile' })]: '/profile/information',
	};

	const { organizationName } = useSelector((state) => state?.corpAccountInfo);

	const [leftActionsData, setLeftActionsData] = useState(getBreadCrumbsListForUrl(location));

	const OrganizationsList = [];

	const getOrganizationsList = () => {
		organizationsList.forEach((entry) => {
			const isMasterUser = entry?.org_type === 'master';

			let body = {
				value: `${URLS.ACCOUNT_APP_URL}/account/session/organization/${entry?.id}`,
				label: `${capitalizeFirstChar(entry?.name)}`,
				className: 'header_organizationslist',
				svg: <Company />,
				isLink: false,
				type: 'organisation',
				helpText: intl.formatMessage({ id: `header.organizationType.${entry?.org_type}` })
			};

			if (isMasterUser) {
				body = { ...body, addtionalInfo: () => <Tag label="BrevoPlus" /> };
			}

			OrganizationsList.push(body);
		  });
	};

	const onClickDropdownCta = (type, value) => {
		const url = value?.value;

		switch (type) {
			case 'initial':
				history.push(url);
				break;

			case 'organisation':
				window.location.replace(url);
				break;

			case 'logout':
				window.location.replace(url);
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		setLeftActionsData(getBreadCrumbsListForUrl(location));
	}, [location.pathname]);

	const profileEntry = {
		type: OptionTypes.SECTION_LIST,
		items: [
			{
				isAvatar: shouldShowAvatar(),
				type: 'initial',
				name: intl.formatMessage({ id: 'header.organization.profile' }),
				value: '/profile/information',
				label: getAvatarLabel(),
				helpText: email,
				isLink: false,
			}
		],
	};

	const logoutEntry = {
		type: OptionTypes.SECTION_LIST,
		items: [
			{
				value: `${URLS.ACCOUNT_APP_URL}/account/logout`,
				label: intl.formatMessage({ id: 'header.organization.logout' }),
				type: 'logout',
				svg: <LogOut />,
				isLink: false,
			}
		],
	};

	const [DropdownOptions, setDropdownOptions] = useState([profileEntry, logoutEntry]);

	useEffect(() => {
		if (fetchingOrganizationsList) return;
		getOrganizationsList();

		if (organizationsList.length) {
			const organizationEntry = {
				type: OptionTypes.SECTION_LIST,
				items: [...OrganizationsList]
			};
			setDropdownOptions([profileEntry, organizationEntry, logoutEntry]);
		}
	}, [fetchingOrganizationsList]);

	return (
		<>
			<div className="globalHeader">
				<GlobalHeader
            	logo={{
						headerWithLogo: hideSidebar, // passing it true, will make logo visible in the globalheader in desktop view.
						clickable: true,
						to: '/',
						target: '_self',
						style: {
							height: '28px'
						},
						logo: 'default',
						tagLine: '',
						className: '',
						isLoading: fetchingCorpInfo,
					}}
					leftActions={{
						data: leftActionsData,
						onSelect: (breadcrumb, index, e) => {
							e.preventDefault();
							history.push(urlMapping[breadcrumb.label]);
						},
						isLoading: fetchingCorpInfo,
					}}
					rightActions={[
						{
							type: GlobalHeaderItems.DROPDOWN,
							active: false,
							isLoading: fetchingCorpInfo,
							isUserProfile: false,
							data: {						// Help dropdown
								isDropdownButton: true,
								options: [
									{
										type: OptionTypes.SECTION_LIST,
										items: [
											{
												value: `${URLS.ACCOUNT_APP_URL}/account/zendesk-support`,
												label: messages['header.help.support'],
												svg: <LifeBuoy />,
												isLink: true,
												target: '_blank'
											},
											{
												value: `https://help.brevo.com/hc/${locale}`,
												label: messages['header.help.resources'],
												svg: <HelpCircle />,
												isLink: true,
												target: '_blank'
											},
											{
												value: `https://developers.${APP_DOMAIN}.com/?lang=${locale}`,
												label: messages['header.help.api'],
												svg: <BookOpen />,
												isLink: true,
												target: '_blank'
											},
										],
									},
								],
								dropdownButtonMetaData: {
									variant: 'tertiary',
									ariaLabel: 'Help',
									icon: <HelpCircle />,
								},
								placement: 'bottomRight',
							},
						},
						{
							type: GlobalHeaderItems.DROPDOWN, // Profile dropdown
							active: false,
							isLoading: fetchingCorpInfo,
							isUserProfile: true,
							data: {
								onSelect: (value) => { onClickDropdownCta(value?.type, value); },
								tooltipData: {
									text: capitalizeFirstChar(organizationName),
									timeout: 200,
									offset: [0, 16],
								},
								isDropdownButton: true,
								controlClassName: 'header_organizationslist',
								options: DropdownOptions,
								dropdownButtonMetaData: {
									variant: 'tertiary',
									ariaLabel: 'Profile',
									showArrowIcon: true,
									icon: <Company />,
									label: capitalizeFirstChar(organizationName),
								},
								placement: 'bottomRight',
							},
						},
					]}
					className=""
				/>
			</div>

		</>
	);
}

GlobalHeaderImpl.propTypes = {
	hideSidebar: PropTypes.bool.isRequired
};

export default GlobalHeaderImpl;
